type Props = {
  mark: string;
};

export const Presenter: React.FC<Props> = (props: Props) => {
  const { mark } = props;

  return (
    <div
      className={`badge ${
        mark === '必須' ? 'badge-neutral' : ''
      } badge-sm ml-2`}
    >
      {mark}
    </div>
  );
};
