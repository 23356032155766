export const Presenter: React.FC = () => {
  // publicフォルダ
  const image = process.env.PUBLIC_URL;

  return (
    <div className="navbar sticky top-0 z-[1000] bg-primary">
      <div className="flex-1 gap-2">
        <a href="/">
          <button className="btn btn-ghost btn-square normal-case">
            <img src={`${image}/logo.svg`} width="42" />
          </button>
        </a>
        <img src={`${image}/title.svg`} width="180" />
      </div>
      <div className="hidden lg:flex">
        <ul className="menu menu-horizontal px-1 gap-2">
          <li>
            <button className="btn btn-link btn-sm text-base-200">
              <a href="/cows">個体情報一覧</a>
            </button>
          </li>
          <li>
            <button className="btn btn-link btn-sm text-base-200">
              <a href="/prescriptions">技術・薬品一覧</a>
            </button>
          </li>
        </ul>
      </div>
      <div className="dropdown dropdown-end dropdown-hover lg:hidden">
        <label tabIndex={0} className="btn btn-accent btn-square btn-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
              clipRule="evenodd"
            />
          </svg>
        </label>
        <ul
          tabIndex={0}
          className="menu menu-sm dropdown-content mt-2 p-2 shadow bg-base-200 rounded-box w-52 gap-2"
        >
          <li>
            <button className="btn btn-link btn-sm text-base-content">
              <a href="/cows">個体情報一覧</a>
            </button>
          </li>
          <li>
            <button className="btn btn-link btn-sm text-base-content">
              <a href="/prescriptions">技術・薬品一覧</a>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};
