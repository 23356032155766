import { useState, useEffect } from 'react';
import Datepicker from 'tailwind-datepicker-react';

type Props = {
  name: string;
  value?: string | undefined;
  disabled?: boolean;
  onChange: (date: Date) => void;
  inputSize?: string | undefined;
  inputColor?: string | undefined;
};

export const Presenter: React.FC<Props> = (props: Props) => {
  const { name, value, disabled, onChange, inputSize, inputColor } = props;

  const options = {
    autoHide: true,
    todayBtn: false,
    clearBtn: false,
    weekDays: ['月', '火', '水', '木', '金', '土', '日'],
    inputDateFormatProp: { day: '2-digit', month: '2-digit', year: 'numeric' },
    datepickerClassNames: 'top-120',
    language: 'en',
    theme: {
      background: 'bg-accent dark:bg-accent',
      todayBtn: '',
      clearBtn: '',
      icons: 'bg-accent dark:bg-accent',
      text: 'text-accent-content dark:text-accent-content',
      disabledText: 'text-neutral dark:text-neutral',
      input: '',
      inputIcon: '',
      selected: 'bg-primary dark:bg-primary',
    },
    icons: {
      prev: () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path
            fillRule="evenodd"
            d="M20.25 12a.75.75 0 01-.75.75H6.31l5.47 5.47a.75.75 0 11-1.06 1.06l-6.75-6.75a.75.75 0 010-1.06l6.75-6.75a.75.75 0 111.06 1.06l-5.47 5.47H19.5a.75.75 0 01.75.75z"
            clipRule="evenodd"
          />
        </svg>
      ),
      next: () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path
            fillRule="evenodd"
            d="M3.75 12a.75.75 0 01.75-.75h13.19l-5.47-5.47a.75.75 0 011.06-1.06l6.75 6.75a.75.75 0 010 1.06l-6.75 6.75a.75.75 0 11-1.06-1.06l5.47-5.47H4.5a.75.75 0 01-.75-.75z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  };

  const [show, setShow] = useState<boolean>(false);
  const handleClose = (state: boolean) => {
    setShow(state);
  };

  /**
   * inputまたはカレンダーの領域以外をクリックするとカレンダーを非表示にする
   */
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      // クリックされた要素が input または カレンダーの領域内であるかどうかを確認
      if (
        target.closest('.input') === null &&
        target.closest('.datepicker') === null &&
        target.closest('button') === null
      ) {
        setShow(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <Datepicker
      onChange={onChange}
      options={options}
      show={show}
      setShow={handleClose}
    >
      <input
        type="text"
        className={`input input-bordered w-full ${inputSize ?? ''} ${
          inputColor ?? ''
        }`}
        name={name}
        placeholder="2024-01-01"
        value={value}
        onFocus={() => setShow(true)}
        disabled={disabled}
        readOnly
      />
    </Datepicker>
  );
};
