/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useRef, useState } from 'react';
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  Column,
} from '@tanstack/react-table';
import { TablePagination } from '../../../components';

type Props = {
  data: any[];
  columns: ColumnDef<any, any>[];
  size?: string;
};

/**
 * 検索用フォーム
 */
const SearchInput = ({ column }: { column: Column<any, any> }) => (
  <input
    type="text"
    className="input input-xs"
    value={(column.getFilterValue() ?? '') as string}
    onChange={(e) => {
      column.setFilterValue(e.target.value);
    }}
    placeholder={`Search...`}
  />
);

export const Presenter: React.FC<Props> = (props: Props) => {
  const { data, columns, size } = props;

  const [sorting, setSorting] = useState<SortingState>([]);

  const tableRef = useRef(null);

  const reactTable = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      <div className="overflow-auto max-h-[40rem] mb-4">
        <table
          className={`table table-pin-rows relative bg-gray-50 ${size || ''}`}
          ref={tableRef}
        >
          <thead>
            {reactTable.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                <th>#</th>
                {headerGroup.headers.map((header, i) => (
                  <th key={header.id + i} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {header.column.getCanSort()
                            ? {
                                asc: ' ↑',
                                desc: ' ↓',
                              }[header.column.getIsSorted() as string] ?? ' ⇅'
                            : ''}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div className="my-2">
                            <SearchInput column={header.column} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {reactTable.getRowModel().rows.map((row, i) => (
              <tr key={row.id}>
                <th>{i + 1}</th>
                {row.getVisibleCells().map((cell, j) => (
                  <td key={cell.id + j}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <TablePagination table={reactTable} />
    </>
  );
};
