import { Presenter } from './Presenter';

type Props = {
  targetId: string;
  icon?: JSX.Element;
  text?: string;
};

export const ScrollButton: React.FC<Props> = (props: Props) => {
  const { targetId, icon, text } = props;

  return <Presenter targetId={targetId} icon={icon} text={text} />;
};
