/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useEffect, useState } from 'react';
import * as apis from '../../../apis';
import {
  Cows as CowsData,
  Pedigree as PedigreeData,
  CreatePedigreeInput,
  UpdatePedigreeInput,
} from '../../../API';
import { PedigreeParams } from '../../../SharedTypes';
import { useToast } from '../../../contexts';
import { Presenter } from './Presenter';

type Props = {
  cow: CowsData | undefined;
};

export const PedigreeForm: React.FC<Props> = (props: Props) => {
  const { cow } = props;

  const [cows, setCows] = useState<CowsData[]>([]);
  const [pedigrees, setPedigrees] = useState<PedigreeData[]>([]);

  const showToast = useToast();

  /**
   * optionalIdに紐づく血統情報を取得
   * @param optionalId
   */
  const getPedigree = async (
    optionalId: string | null | undefined,
  ): Promise<PedigreeData | undefined> => {
    if (!optionalId) {
      return undefined;
    }

    try {
      const result = await apis.fetchPedigreesByOptionalId(optionalId);

      if (result.length !== 0) {
        return result[0];
      }
      return undefined;
    } catch (error) {
      showToast({
        text: `データ取得に失敗しました。${JSON.stringify(error)}`,
        type: 'error',
      });
      return undefined;
    }
  };

  /**
   * 送信ボタン押下時のAPI実行処理
   * @param params PedigreeParams
   */
  const submitPedigree = async (params: PedigreeParams) => {
    try {
      // データベースをoptional_idで検索
      const result = await getPedigree(params.optionalId);
      if (!result) {
        // レコードがなければ登録
        // データ登録用パラメータ
        const details: CreatePedigreeInput = {
          optional_id: params.optionalId,
          sire_optional_id: params.sireOptionalId,
          dam_optional_id: params.damOptionalId,
        };

        // データを登録
        await apis.createPedigree(details);
      } else {
        // レコードがあれば更新
        // データ更新用パラメータ
        const details: UpdatePedigreeInput = {
          id: result.id,
          optional_id: params.optionalId,
          sire_optional_id: params.sireOptionalId,
          dam_optional_id: params.damOptionalId,
          _version: result._version,
        };

        // データを更新
        await apis.updatePedigree(details);
      }

      showToast({
        text: `データを更新しました。`,
        type: 'success',
      });
    } catch (error) {
      showToast({
        text: `エラーが発生しました。${error}`,
        type: 'error',
      });
    }
  };

  /**
   * 最初に個体情報一覧を取得
   */
  useEffect(() => {
    apis
      .fetchCows()
      .then((result) => {
        setCows(result);
      })
      .catch((error) => {
        showToast({
          text: `データ取得に失敗しました。${JSON.stringify(error)}`,
          type: 'error',
        });
      });
  }, []);

  /**
   * 最初に血統情報一覧を取得
   */
  useEffect(() => {
    apis
      .fetchPedigrees()
      .then((result) => {
        setPedigrees(result);
      })
      .catch((error) => {
        showToast({
          text: `データ取得に失敗しました。${JSON.stringify(error)}`,
          type: 'error',
        });
      });
  }, []);

  return (
    <Presenter
      cow={cow}
      cows={cows}
      pedigrees={pedigrees}
      getPedigree={getPedigree}
      submitPedigree={submitPedigree}
    />
  );
};
