/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import {
  Prescriptions,
  ModelPrescriptionsFilterInput,
  ListPrescriptionsQuery,
  ListPrescriptionsQueryVariables,
  CreatePrescriptionsInput,
  CreatePrescriptionsMutation,
  DeletePrescriptionsInput,
  DeletePrescriptionsMutation,
  UpdatePrescriptionsInput,
  UpdatePrescriptionsMutation
} from '../API';

/**
 * GraphQLでPrescriptions一覧を取得
 */
export const fetchPrescriptions = async (): Promise<Prescriptions[]> => {
  const allItems: Prescriptions[] = [];
  let nextToken: string | undefined | null = null;
  const filter: ModelPrescriptionsFilterInput = { _deleted: { ne: true } };

  do {
    const variables: ListPrescriptionsQueryVariables = {
      filter,
      limit: 20,
      nextToken,
    };

    // eslint-disable-next-line no-await-in-loop
    const response = await API.graphql<GraphQLQuery<ListPrescriptionsQuery>>({
      query: queries.listPrescriptions,
      variables,
    });

    // @ts-ignore
    const { items, nextToken: newNextToken } = response.data?.listPrescriptions || {
      items: [],
      nextToken: null,
    };

    allItems.push(...items);
    nextToken = newNextToken;
  } while (nextToken);

  // optional_idでソートする比較関数
  const compareById = (a: Prescriptions, b: Prescriptions) => {
    if (a.optional_id && b.optional_id) {
      return a.optional_id.localeCompare(b.optional_id);
    }
    return 0;
  };
  
  // optional_idでソート
  allItems.sort(compareById);

  return allItems;
};

/**
 * GraphQLでOptionalIdをキーにPrescriptions一覧を取得
 */
export const fetchPrescriptionsByOptionalId = async (
  optionalId: string | undefined,
): Promise<Prescriptions[]> => {
  if (!optionalId) {
    return [];
  }

  const allItems: Prescriptions[] = [];
  let nextToken: string | undefined | null = null;
  const filter: ModelPrescriptionsFilterInput = {
    and: [
      { optional_id: { eq: optionalId } },
      { _deleted: { ne: true } }
    ]
  };

  do {
    const variables: ListPrescriptionsQueryVariables = {
      filter,
      limit: 20,
      nextToken,
    };

    // eslint-disable-next-line no-await-in-loop
    const response = await API.graphql<GraphQLQuery<ListPrescriptionsQuery>>({
      query: queries.listPrescriptions,
      variables,
    });

    // @ts-ignore
    const { items, nextToken: newNextToken } = response.data?.listPrescriptions || {
      items: [],
      nextToken: null,
    };

    allItems.push(...items);
    nextToken = newNextToken;
  } while (nextToken);

  return allItems;
};

/**
 * GraphQLでPrescriptionsに登録
 */
export const createPrescriptions = async (
  details: CreatePrescriptionsInput,
): Promise<Prescriptions> => {
  const item = await API.graphql<GraphQLQuery<CreatePrescriptionsMutation>>(
    graphqlOperation(mutations.createPrescriptions, { input: details })
  );

  // @ts-ignore
  return item.data.createPrescriptions;
};

/**
 * GraphQLでPrescriptionsを更新
 */
export const updatePrescriptions = async (
  details: UpdatePrescriptionsInput
): Promise<void> => {
  await API.graphql<GraphQLQuery<UpdatePrescriptionsMutation>>({
    query: mutations.updatePrescriptions,
    variables: { input: details },
  });
};

/**
 * GraphQLでPrescriptionsからデータを削除
 */
export const deletePrescriptions = async (details: DeletePrescriptionsInput)  => {
  await API.graphql<GraphQLQuery<DeletePrescriptionsMutation>>({ 
    query: mutations.deletePrescriptions, 
    variables: { input: details }
  });
};
