/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ColumnDef } from '@tanstack/react-table';
import { Prescriptions as PrescriptionsData } from '../../API';
import {
  Footer,
  Navbar,
  ScrollButton,
  SectionTitle,
  Table,
  PrescriptionsForm,
} from '../../components';

type Props = {
  prescriptions: PrescriptionsData[];
  setPrescriptions: React.Dispatch<React.SetStateAction<PrescriptionsData[]>>;
};

export const Presenter: React.FC<Props> = (props: Props) => {
  const { prescriptions, setPrescriptions } = props;

  /**
   * テーブルのカラム設定
   */
  const columns: ColumnDef<{}, any>[] = [
    {
      accessorKey: 'optional_id',
      header: 'ID',
      cell: (row) => (
        <a href={`/prescriptions/${row.getValue()}`}>
          <button className="btn btn-link btn-sm text-accent normal-case inline-block gap-0">
            {row.getValue()}
          </button>
        </a>
      ),
    },
    {
      accessorKey: 'name',
      header: '技術・薬品名',
    },
  ];

  return (
    <div className="flex flex-col h-screen">
      <Navbar />
      <div className="flex flex-col justify-center lg:w-2/3 mx-4 lg:mx-auto">
        <div className="flex justify-between">
          <SectionTitle
            svgIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M2.625 6.75a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0A.75.75 0 018.25 6h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75zM2.625 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zM7.5 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12A.75.75 0 017.5 12zm-4.875 5.25a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75z"
                  clipRule="evenodd"
                />
              </svg>
            }
            title="技術・薬品一覧"
          />
          <ScrollButton
            targetId="prescriptionsform"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M20.03 4.72a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 11.69l6.97-6.97a.75.75 0 011.06 0zm0 6a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 111.06-1.06L12 17.69l6.97-6.97a.75.75 0 011.06 0z"
                  clipRule="evenodd"
                />
              </svg>
            }
            text="新規登録"
          />
        </div>
        <Table data={prescriptions} columns={columns} />
      </div>
      <PrescriptionsForm
        formType="create"
        prescriptions={prescriptions}
        setPrescriptions={setPrescriptions}
      />
      <Footer />
    </div>
  );
};
