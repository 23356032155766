import { createBrowserRouter } from 'react-router-dom';
import {
  NotFound,
  Cows,
  CowsDetails,
  Prescriptions,
  PrescriptionsDetails,
  DailyReports,
} from './pages';

export const router = createBrowserRouter([
  { path: '*', element: <NotFound /> },
  { path: '/', element: <Cows /> },
  { path: 'cows', element: <Cows /> },
  { path: 'cows/:cowsOptionalId', element: <CowsDetails /> },
  { path: 'prescriptions', element: <Prescriptions /> },
  {
    path: 'prescriptions/:prescriptionsOptionalId',
    element: <PrescriptionsDetails />,
  },
  { path: 'daily_reports/:cowsOptionalId', element: <DailyReports /> },
]);
