import {
  ReactNode,
  useState,
  useContext,
  useCallback,
  createContext,
} from 'react';
import { createPortal } from 'react-dom';
import { Toast } from '../components';

type Props = {
  children: ReactNode;
};

type ToastTypes = 'info' | 'success' | 'warning' | 'error';

/**
 * ToastContextの作成
 */
const ToastContext = createContext(
  // eslint-disable-next-line no-empty-pattern
  ({}: { text: string; type?: ToastTypes }) => {},
);
ToastContext.displayName = 'ToastContext';

/**
 * useContextによるToastContextのGlobal化
 */
export const useToast = () => useContext(ToastContext);

/**
 * ToastProviderの定義
 */
export const ToastProvider: React.FC<Props> = ({ children }) => {
  const [toastText, setToastText] = useState('');
  const [toastType, setToastType] = useState<ToastTypes>('info');
  const [visible, setVisible] = useState(false);

  /**
   * Toastを表示
   */
  const showToast = ({
    text,
    type = 'info',
  }: {
    text: string;
    type?: ToastTypes;
  }) => {
    setToastText(text);
    setToastType(type);
    setVisible(true);
  };

  /**
   * Toastを非表示
   */
  const hideToast = useCallback(() => setVisible(false), []);

  return (
    <ToastContext.Provider value={showToast}>
      {createPortal(
        <Toast
          toastText={toastText}
          toastType={toastType}
          visible={visible}
          hideToast={hideToast}
        />,
        document.body,
      )}
      {children}
    </ToastContext.Provider>
  );
};
