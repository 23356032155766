/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getPedigree = /* GraphQL */ `query GetPedigree($id: ID!) {
  getPedigree(id: $id) {
    id
    optional_id
    sire_optional_id
    dam_optional_id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPedigreeQueryVariables,
  APITypes.GetPedigreeQuery
>;
export const listPedigrees = /* GraphQL */ `query ListPedigrees(
  $filter: ModelPedigreeFilterInput
  $limit: Int
  $nextToken: String
) {
  listPedigrees(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      optional_id
      sire_optional_id
      dam_optional_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPedigreesQueryVariables,
  APITypes.ListPedigreesQuery
>;
export const syncPedigrees = /* GraphQL */ `query SyncPedigrees(
  $filter: ModelPedigreeFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPedigrees(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      optional_id
      sire_optional_id
      dam_optional_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPedigreesQueryVariables,
  APITypes.SyncPedigreesQuery
>;
export const getMovementHistory = /* GraphQL */ `query GetMovementHistory($id: ID!) {
  getMovementHistory(id: $id) {
    id
    cowsID
    location
    move_date
    reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMovementHistoryQueryVariables,
  APITypes.GetMovementHistoryQuery
>;
export const listMovementHistories = /* GraphQL */ `query ListMovementHistories(
  $filter: ModelMovementHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listMovementHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cowsID
      location
      move_date
      reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMovementHistoriesQueryVariables,
  APITypes.ListMovementHistoriesQuery
>;
export const syncMovementHistories = /* GraphQL */ `query SyncMovementHistories(
  $filter: ModelMovementHistoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMovementHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      cowsID
      location
      move_date
      reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMovementHistoriesQueryVariables,
  APITypes.SyncMovementHistoriesQuery
>;
export const movementHistoriesByCowsID = /* GraphQL */ `query MovementHistoriesByCowsID(
  $cowsID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMovementHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  movementHistoriesByCowsID(
    cowsID: $cowsID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cowsID
      location
      move_date
      reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MovementHistoriesByCowsIDQueryVariables,
  APITypes.MovementHistoriesByCowsIDQuery
>;
export const getPrescriptions = /* GraphQL */ `query GetPrescriptions($id: ID!) {
  getPrescriptions(id: $id) {
    id
    optional_id
    name
    DailyReportDetails {
      items {
        id
        dailyreportsID
        DailyReports {
          id
          cowsID
          exam_date
          exam_record
          photo1
          photo2
          recorded_by
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        b_point
        a_point
        usage_amount
        unit_price
        prescriptionsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPrescriptionsQueryVariables,
  APITypes.GetPrescriptionsQuery
>;
export const listPrescriptions = /* GraphQL */ `query ListPrescriptions(
  $filter: ModelPrescriptionsFilterInput
  $limit: Int
  $nextToken: String
) {
  listPrescriptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      optional_id
      name
      DailyReportDetails {
        items {
          id
          dailyreportsID
          b_point
          a_point
          usage_amount
          unit_price
          prescriptionsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPrescriptionsQueryVariables,
  APITypes.ListPrescriptionsQuery
>;
export const syncPrescriptions = /* GraphQL */ `query SyncPrescriptions(
  $filter: ModelPrescriptionsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPrescriptions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      optional_id
      name
      DailyReportDetails {
        items {
          id
          dailyreportsID
          b_point
          a_point
          usage_amount
          unit_price
          prescriptionsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPrescriptionsQueryVariables,
  APITypes.SyncPrescriptionsQuery
>;
export const getDailyReportDetails = /* GraphQL */ `query GetDailyReportDetails($id: ID!) {
  getDailyReportDetails(id: $id) {
    id
    dailyreportsID
    DailyReports {
      id
      cowsID
      Cows {
        id
        DairyReports {
          nextToken
          startedAt
          __typename
        }
        optional_id
        birth_date
        birth_weight
        sex
        neutering_date
        dam_birth_order
        MovementHistories {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DailyReportDetails {
        items {
          id
          dailyreportsID
          b_point
          a_point
          usage_amount
          unit_price
          prescriptionsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      exam_date
      exam_record
      photo1
      photo2
      recorded_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    b_point
    a_point
    usage_amount
    unit_price
    prescriptionsID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDailyReportDetailsQueryVariables,
  APITypes.GetDailyReportDetailsQuery
>;
export const listDailyReportDetails = /* GraphQL */ `query ListDailyReportDetails(
  $filter: ModelDailyReportDetailsFilterInput
  $limit: Int
  $nextToken: String
) {
  listDailyReportDetails(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      dailyreportsID
      DailyReports {
        id
        cowsID
        Cows {
          id
          optional_id
          birth_date
          birth_weight
          sex
          neutering_date
          dam_birth_order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DailyReportDetails {
          nextToken
          startedAt
          __typename
        }
        exam_date
        exam_record
        photo1
        photo2
        recorded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      b_point
      a_point
      usage_amount
      unit_price
      prescriptionsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDailyReportDetailsQueryVariables,
  APITypes.ListDailyReportDetailsQuery
>;
export const syncDailyReportDetails = /* GraphQL */ `query SyncDailyReportDetails(
  $filter: ModelDailyReportDetailsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDailyReportDetails(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      dailyreportsID
      DailyReports {
        id
        cowsID
        Cows {
          id
          optional_id
          birth_date
          birth_weight
          sex
          neutering_date
          dam_birth_order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DailyReportDetails {
          nextToken
          startedAt
          __typename
        }
        exam_date
        exam_record
        photo1
        photo2
        recorded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      b_point
      a_point
      usage_amount
      unit_price
      prescriptionsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDailyReportDetailsQueryVariables,
  APITypes.SyncDailyReportDetailsQuery
>;
export const dailyReportDetailsByDailyreportsID = /* GraphQL */ `query DailyReportDetailsByDailyreportsID(
  $dailyreportsID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDailyReportDetailsFilterInput
  $limit: Int
  $nextToken: String
) {
  dailyReportDetailsByDailyreportsID(
    dailyreportsID: $dailyreportsID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      dailyreportsID
      DailyReports {
        id
        cowsID
        Cows {
          id
          optional_id
          birth_date
          birth_weight
          sex
          neutering_date
          dam_birth_order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DailyReportDetails {
          nextToken
          startedAt
          __typename
        }
        exam_date
        exam_record
        photo1
        photo2
        recorded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      b_point
      a_point
      usage_amount
      unit_price
      prescriptionsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DailyReportDetailsByDailyreportsIDQueryVariables,
  APITypes.DailyReportDetailsByDailyreportsIDQuery
>;
export const dailyReportDetailsByPrescriptionsID = /* GraphQL */ `query DailyReportDetailsByPrescriptionsID(
  $prescriptionsID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDailyReportDetailsFilterInput
  $limit: Int
  $nextToken: String
) {
  dailyReportDetailsByPrescriptionsID(
    prescriptionsID: $prescriptionsID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      dailyreportsID
      DailyReports {
        id
        cowsID
        Cows {
          id
          optional_id
          birth_date
          birth_weight
          sex
          neutering_date
          dam_birth_order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DailyReportDetails {
          nextToken
          startedAt
          __typename
        }
        exam_date
        exam_record
        photo1
        photo2
        recorded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      b_point
      a_point
      usage_amount
      unit_price
      prescriptionsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DailyReportDetailsByPrescriptionsIDQueryVariables,
  APITypes.DailyReportDetailsByPrescriptionsIDQuery
>;
export const getDailyReports = /* GraphQL */ `query GetDailyReports($id: ID!) {
  getDailyReports(id: $id) {
    id
    cowsID
    Cows {
      id
      DairyReports {
        items {
          id
          cowsID
          exam_date
          exam_record
          photo1
          photo2
          recorded_by
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      optional_id
      birth_date
      birth_weight
      sex
      neutering_date
      dam_birth_order
      MovementHistories {
        items {
          id
          cowsID
          location
          move_date
          reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    DailyReportDetails {
      items {
        id
        dailyreportsID
        DailyReports {
          id
          cowsID
          exam_date
          exam_record
          photo1
          photo2
          recorded_by
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        b_point
        a_point
        usage_amount
        unit_price
        prescriptionsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    exam_date
    exam_record
    photo1
    photo2
    recorded_by
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDailyReportsQueryVariables,
  APITypes.GetDailyReportsQuery
>;
export const listDailyReports = /* GraphQL */ `query ListDailyReports(
  $filter: ModelDailyReportsFilterInput
  $limit: Int
  $nextToken: String
) {
  listDailyReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cowsID
      Cows {
        id
        DairyReports {
          nextToken
          startedAt
          __typename
        }
        optional_id
        birth_date
        birth_weight
        sex
        neutering_date
        dam_birth_order
        MovementHistories {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DailyReportDetails {
        items {
          id
          dailyreportsID
          b_point
          a_point
          usage_amount
          unit_price
          prescriptionsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      exam_date
      exam_record
      photo1
      photo2
      recorded_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDailyReportsQueryVariables,
  APITypes.ListDailyReportsQuery
>;
export const syncDailyReports = /* GraphQL */ `query SyncDailyReports(
  $filter: ModelDailyReportsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDailyReports(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      cowsID
      Cows {
        id
        DairyReports {
          nextToken
          startedAt
          __typename
        }
        optional_id
        birth_date
        birth_weight
        sex
        neutering_date
        dam_birth_order
        MovementHistories {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DailyReportDetails {
        items {
          id
          dailyreportsID
          b_point
          a_point
          usage_amount
          unit_price
          prescriptionsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      exam_date
      exam_record
      photo1
      photo2
      recorded_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDailyReportsQueryVariables,
  APITypes.SyncDailyReportsQuery
>;
export const dailyReportsByCowsID = /* GraphQL */ `query DailyReportsByCowsID(
  $cowsID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDailyReportsFilterInput
  $limit: Int
  $nextToken: String
) {
  dailyReportsByCowsID(
    cowsID: $cowsID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cowsID
      Cows {
        id
        DairyReports {
          nextToken
          startedAt
          __typename
        }
        optional_id
        birth_date
        birth_weight
        sex
        neutering_date
        dam_birth_order
        MovementHistories {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DailyReportDetails {
        items {
          id
          dailyreportsID
          b_point
          a_point
          usage_amount
          unit_price
          prescriptionsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      exam_date
      exam_record
      photo1
      photo2
      recorded_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DailyReportsByCowsIDQueryVariables,
  APITypes.DailyReportsByCowsIDQuery
>;
export const getCows = /* GraphQL */ `query GetCows($id: ID!) {
  getCows(id: $id) {
    id
    DairyReports {
      items {
        id
        cowsID
        Cows {
          id
          optional_id
          birth_date
          birth_weight
          sex
          neutering_date
          dam_birth_order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DailyReportDetails {
          nextToken
          startedAt
          __typename
        }
        exam_date
        exam_record
        photo1
        photo2
        recorded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    optional_id
    birth_date
    birth_weight
    sex
    neutering_date
    dam_birth_order
    MovementHistories {
      items {
        id
        cowsID
        location
        move_date
        reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCowsQueryVariables, APITypes.GetCowsQuery>;
export const listCows = /* GraphQL */ `query ListCows($filter: ModelCowsFilterInput, $limit: Int, $nextToken: String) {
  listCows(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      DairyReports {
        items {
          id
          cowsID
          exam_date
          exam_record
          photo1
          photo2
          recorded_by
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      optional_id
      birth_date
      birth_weight
      sex
      neutering_date
      dam_birth_order
      MovementHistories {
        items {
          id
          cowsID
          location
          move_date
          reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListCowsQueryVariables, APITypes.ListCowsQuery>;
export const syncCows = /* GraphQL */ `query SyncCows(
  $filter: ModelCowsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCows(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      DairyReports {
        items {
          id
          cowsID
          exam_date
          exam_record
          photo1
          photo2
          recorded_by
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      optional_id
      birth_date
      birth_weight
      sex
      neutering_date
      dam_birth_order
      MovementHistories {
        items {
          id
          cowsID
          location
          move_date
          reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncCowsQueryVariables, APITypes.SyncCowsQuery>;
