/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { forwardRef, useImperativeHandle } from 'react';
import * as apis from '../../../apis';
import {
  MovementHistory as MovementHistoryData,
  CreateMovementHistoryInput,
  UpdateMovementHistoryInput,
  DeleteMovementHistoryInput,
} from '../../../API';
import { MovementHistoryParams } from '../../../SharedTypes';
import { useToast } from '../../../contexts';
import { Presenter } from './Presenter';

type Props = {
  cowsId: string | undefined;
  editMovementHistory: MovementHistoryData | undefined;
  setEditMovementHistory: React.Dispatch<MovementHistoryData | undefined>;
  setMovementHistories: React.Dispatch<MovementHistoryData[]>;
};

export const MovementHistoryForm = forwardRef((props: Props, ref) => {
  const {
    cowsId,
    editMovementHistory,
    setEditMovementHistory,
    setMovementHistories,
  } = props;

  const showToast = useToast();

  /**
   * 送信ボタン押下時のAPI実行処理
   * @param params
   */
  const submitMovementHistory = (params: MovementHistoryParams) => {
    if (!cowsId) {
      return;
    }

    // editMovementHistoryがあれば更新、なければ登録
    if (editMovementHistory) {
      // データ更新用パラメータ
      const details: UpdateMovementHistoryInput = {
        id: editMovementHistory.id,
        cowsID: cowsId,
        location: params.location,
        move_date: params.moveDate,
        reason: params.reason,
        _version: editMovementHistory._version,
      };

      // データ更新
      apis
        .updateMovementHistory(details)
        .then(() => {
          showToast({
            text: `データを更新しました。`,
            type: 'success',
          });

          // 編集中の移動履歴のクリア
          setEditMovementHistory(undefined);

          // 成功したら移動履歴一覧を再取得する
          apis
            .fetchMovementHistoriesByCowsId(cowsId)
            .then((result) => {
              setMovementHistories(result);
            })
            .catch((error) => {
              showToast({
                text: `データ取得に失敗しました。${JSON.stringify(error)}`,
                type: 'error',
              });
            });
        })
        .catch((error) => {
          showToast({
            text: `データ更新に失敗しました。${JSON.stringify(error)}`,
            type: 'error',
          });
        });
    } else {
      // データ登録用パラメータ
      const details: CreateMovementHistoryInput = {
        cowsID: cowsId,
        location: params.location,
        move_date: params.moveDate,
        reason: params.reason,
      };

      // データ登録
      apis
        .createMovementHistory(details)
        .then(() => {
          showToast({
            text: `データを登録しました。`,
            type: 'success',
          });

          // 成功したら移動履歴一覧を再取得する
          apis
            .fetchMovementHistoriesByCowsId(cowsId)
            .then((result) => {
              setMovementHistories(result);
            })
            .catch((error) => {
              showToast({
                text: `データ取得に失敗しました。${JSON.stringify(error)}`,
                type: 'error',
              });
            });
        })
        .catch((error) => {
          showToast({
            text: `データ登録に失敗しました。${JSON.stringify(error)}`,
            type: 'error',
          });
        });
    }
  };

  /**
   * 削除ボタン押下時のAPI実行処理
   */
  const deleteMovementHistory = () => {
    if (!cowsId || !editMovementHistory) {
      return;
    }

    // データ削除用パラメータ
    const details: DeleteMovementHistoryInput = {
      id: editMovementHistory.id,
      _version: editMovementHistory._version,
    };

    // データ削除
    apis
      .deleteMovementHistory(details)
      .then(() => {
        showToast({
          text: `データを削除しました。`,
          type: 'success',
        });

        // 編集中の移動履歴のクリア
        setEditMovementHistory(undefined);

        // 成功したら移動履歴を再取得する
        apis
          .fetchMovementHistoriesByCowsId(cowsId)
          .then((result) => {
            setMovementHistories(result);
          })
          .catch((error) => {
            showToast({
              text: `データ取得に失敗しました。${JSON.stringify(error)}`,
              type: 'error',
            });
          });
      })
      .catch((error) => {
        showToast({
          text: `データ削除に失敗しました。${JSON.stringify(error)}`,
          type: 'error',
        });
      });
  };

  /**
   * 親コンポーネントから実行できるようにするuseImperativeHandle
   */
  useImperativeHandle(ref, () => ({
    deleteMovementHistory,
  }));

  return (
    <Presenter
      cowsId={cowsId}
      editMovementHistory={editMovementHistory}
      submitMovementHistory={submitMovementHistory}
    />
  );
});
