/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import * as apis from '../../../apis';
import {
  DailyReportDetails as DailyReportDetailsData,
  Prescriptions as PrescriptionsData,
  CreateDailyReportDetailsInput,
  UpdateDailyReportDetailsInput,
  DeleteDailyReportDetailsInput,
} from '../../../API';
import { DailyReportDetailsParams } from '../../../SharedTypes';
import { useToast } from '../../../contexts';
import { Presenter } from './Presenter';

type Props = {
  dailyReportsId: string | undefined;
  editDailyReportDetail: DailyReportDetailsData | undefined;
  setEditDailyReportDetail: React.Dispatch<DailyReportDetailsData | undefined>;
  setDailyReportDetails: React.Dispatch<DailyReportDetailsData[]>;
};

export const DailyReportDetailsForm = forwardRef((props: Props, ref) => {
  const {
    dailyReportsId,
    editDailyReportDetail,
    setEditDailyReportDetail,
    setDailyReportDetails,
  } = props;

  const [prescriptions, setPrescriptions] = useState<PrescriptionsData[]>([]);

  const showToast = useToast();

  /**
   * 送信ボタン押下時のAPI実行処理
   * @param params
   */
  const submitDailyReportDetails = (params: DailyReportDetailsParams) => {
    if (!dailyReportsId) {
      return;
    }

    // editDailyReportDetailがあれば更新、なければ登録
    if (editDailyReportDetail) {
      // データ更新用パラメータ
      const details: UpdateDailyReportDetailsInput = {
        id: editDailyReportDetail.id,
        dailyreportsID: dailyReportsId,
        a_point: params.aPoint,
        b_point: params.bPoint,
        usage_amount: params.usageAmount,
        unit_price: params.unitPrice,
        _version: editDailyReportDetail._version,
        prescriptionsID: params.prescriptionsID,
      };

      // データ更新
      apis
        .updateDailyReportDetails(details)
        .then(() => {
          showToast({
            text: `データを更新しました。`,
            type: 'success',
          });

          // 編集中の診察結果詳細のクリア
          setEditDailyReportDetail(undefined);

          // 成功したら診察結果詳細一覧を再取得する
          apis
            .fetchDailyReportDetailsByDailyreportsId(dailyReportsId)
            .then((result) => {
              // dailyReport.prescriptionsIDがprescriptionsに含まれない場合、表示しない
              const filteredResult = result.filter((dailyReport) =>
                prescriptions.some(
                  (prescription) =>
                    prescription.id === dailyReport.prescriptionsID,
                ),
              );
              setDailyReportDetails(filteredResult);
            })
            .catch((error) => {
              showToast({
                text: `データ取得に失敗しました。${JSON.stringify(error)}`,
                type: 'error',
              });
            });
        })
        .catch((error) => {
          showToast({
            text: `データ更新に失敗しました。${JSON.stringify(error)}`,
            type: 'error',
          });
        });
    } else {
      // データ登録用パラメータ
      const details: CreateDailyReportDetailsInput = {
        dailyreportsID: dailyReportsId,
        a_point: params.aPoint,
        b_point: params.bPoint,
        usage_amount: params.usageAmount,
        unit_price: params.unitPrice,
        prescriptionsID: params.prescriptionsID,
      };

      // データ登録
      apis
        .createDailyReportDetails(details)
        .then(() => {
          showToast({
            text: `データを登録しました。`,
            type: 'success',
          });

          // 成功したら診察結果詳細一覧を再取得する
          apis
            .fetchDailyReportDetailsByDailyreportsId(dailyReportsId)
            .then((result) => {
              // dailyReport.prescriptionsIDがprescriptionsに含まれない場合、表示しない
              const filteredResult = result.filter((dailyReport) =>
                prescriptions.some(
                  (prescription) =>
                    prescription.id === dailyReport.prescriptionsID,
                ),
              );
              setDailyReportDetails(filteredResult);
            })
            .catch((error) => {
              showToast({
                text: `データ取得に失敗しました。${JSON.stringify(error)}`,
                type: 'error',
              });
            });
        })
        .catch((error) => {
          showToast({
            text: `データ登録に失敗しました。${JSON.stringify(error)}`,
            type: 'error',
          });
        });
    }
  };

  /**
   * 削除ボタン押下時のAPI実行処理
   */
  const deleteDailyReportDetails = () => {
    if (!dailyReportsId || !editDailyReportDetail) {
      return;
    }

    // データ削除用パラメータ
    const details: DeleteDailyReportDetailsInput = {
      id: editDailyReportDetail.id,
      _version: editDailyReportDetail._version,
    };

    // データ削除
    apis
      .deleteDailyReportDetails(details)
      .then(() => {
        showToast({
          text: `データを削除しました。`,
          type: 'success',
        });

        // 編集中の診察結果詳細のクリア
        setEditDailyReportDetail(undefined);

        // 成功したら診察結果詳細一覧を再取得する
        apis
          .fetchDailyReportDetailsByDailyreportsId(dailyReportsId)
          .then((result) => {
            // dailyReport.prescriptionsIDがprescriptionsに含まれない場合、表示しない
            const filteredResult = result.filter((dailyReport) =>
              prescriptions.some(
                (prescription) =>
                  prescription.id === dailyReport.prescriptionsID,
              ),
            );
            setDailyReportDetails(filteredResult);
          })
          .catch((error) => {
            showToast({
              text: `データ取得に失敗しました。${JSON.stringify(error)}`,
              type: 'error',
            });
          });
      })
      .catch((error) => {
        showToast({
          text: `データ削除に失敗しました。${JSON.stringify(error)}`,
          type: 'error',
        });
      });
  };

  /**
   * 親コンポーネントから実行できるようにするuseImperativeHandle
   */
  useImperativeHandle(ref, () => ({
    deleteDailyReportDetails,
  }));

  /**
   * 最初に技術・薬品一覧を取得
   */
  useEffect(() => {
    apis
      .fetchPrescriptions()
      .then((result) => {
        setPrescriptions(result);
      })
      .catch((error) => {
        showToast({
          text: `データ取得に失敗しました。${JSON.stringify(error)}`,
          type: 'error',
        });
      });
  }, []);

  return (
    <Presenter
      dailyReportsId={dailyReportsId}
      editDailyReportDetail={editDailyReportDetail}
      prescriptions={prescriptions}
      submitDailyReportDetails={submitDailyReportDetails}
    />
  );
});
