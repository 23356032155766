/* eslint-disable @typescript-eslint/no-misused-promises */
import { useNavigate } from 'react-router-dom';
import * as apis from '../../../apis';
import {
  Prescriptions as PrescriptionsData,
  CreatePrescriptionsInput,
  DeletePrescriptionsInput,
  UpdatePrescriptionsInput,
} from '../../../API';
import { PrescriptionsParams } from '../../../SharedTypes';
import { useToast } from '../../../contexts';
import { Presenter } from './Presenter';

type Props = {
  formType: 'create' | 'update';
  prescriptions?: PrescriptionsData[];
  setPrescriptions?: React.Dispatch<React.SetStateAction<PrescriptionsData[]>>;
  prescription?: PrescriptionsData;
};

export const PrescriptionsForm: React.FC<Props> = (props: Props) => {
  const { formType, prescriptions, setPrescriptions, prescription } = props;

  const showToast = useToast();
  const navigate = useNavigate();

  /**
   * optionalIdが重複していないかチェックする
   * @param optionalId
   * @returns
   */
  const checkDuplicateId = (optionalId: string) => {
    if (!prescriptions) {
      return false;
    }

    const isDuplicate = prescriptions.some(
      (item) => item.optional_id === optionalId,
    );
    return isDuplicate;
  };

  /**
   * 送信ボタン押下時のAPI実行処理
   * @param optionalId
   */
  const submitPrescriptions = async (params: PrescriptionsParams) => {
    try {
      if (formType === 'create') {
        // IDの重複チェック
        if (checkDuplicateId(params.optionalId)) {
          showToast({
            text: `IDが重複しているため登録できませんでした。`,
            type: 'warning',
          });
          return;
        }

        // データ登録用パラメータ
        const details: CreatePrescriptionsInput = {
          optional_id: params.optionalId,
          name: params.name,
        };

        // データ登録
        await apis.createPrescriptions(details);

        showToast({
          text: `データを登録しました。`,
          type: 'success',
        });

        // 技術・薬品一覧を再取得する
        if (!setPrescriptions) {
          return;
        }
        const fetchResult = await apis.fetchPrescriptions();
        setPrescriptions(fetchResult);
      } else if (formType === 'update') {
        if (!prescription) {
          return;
        }

        // データ更新用パラメータ
        const details: UpdatePrescriptionsInput = {
          id: prescription.id,
          optional_id: params.optionalId,
          name: params.name,
          _version: prescription._version,
        };

        // データ更新
        await apis.updatePrescriptions(details);

        showToast({
          text: `データを更新しました。`,
          type: 'success',
        });
      }
    } catch (error) {
      showToast({
        text: `エラーが発生しました。${JSON.stringify(error)}`,
        type: 'error',
      });
    }
  };

  /**
   * 削除ボタン押下時のAPI実行処理
   */
  const deletePrescriptions = () => {
    if (!prescription) {
      return;
    }

    // データ削除用パラメータ
    const details: DeletePrescriptionsInput = {
      id: prescription.id,
      _version: prescription._version,
    };

    // データ削除
    apis
      .deletePrescriptions(details)
      .then(() => {
        showToast({
          text: `データを削除しました。`,
          type: 'success',
        });

        // 技術・薬品一覧へ遷移
        navigate('/prescriptions');
      })
      .catch((error) => {
        showToast({
          text: `データ削除に失敗しました。${JSON.stringify(error)}`,
          type: 'error',
        });
      });
  };

  return (
    <Presenter
      formType={formType}
      prescription={prescription}
      submitPrescriptions={submitPrescriptions}
      deletePrescriptions={deletePrescriptions}
    />
  );
};
