import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as apis from '../../apis';
import { Prescriptions as PrescriptionsData } from '../../API';
import { useToast } from '../../contexts';
import { Presenter } from './Presenter';

export const PrescriptionsDetails = () => {
  const [prescription, setPrescription] = useState<PrescriptionsData>();

  const showToast = useToast();
  const urlParams = useParams<{ prescriptionsOptionalId: string }>();
  const { prescriptionsOptionalId } = urlParams;
  const navigate = useNavigate();

  /**
   * 最初に技術・薬品を取得
   */
  useEffect(() => {
    apis
      .fetchPrescriptionsByOptionalId(prescriptionsOptionalId)
      .then((result) => {
        if (result.length === 0) {
          // データがない場合はNotFoundへ遷移
          navigate('/notfound');
          return;
        }
        setPrescription(result[0]);
      })
      .catch((error) => {
        showToast({
          text: `データ取得に失敗しました。${JSON.stringify(error)}`,
          type: 'error',
        });
      });
  }, []);

  return <Presenter prescription={prescription} />;
};
