import { Cows as CowsData } from '../../API';
import {
  Footer,
  Navbar,
  SectionTitle,
  CowsForm,
  EarTag,
  PedigreeForm,
} from '../../components';

type Props = {
  cow: CowsData | undefined;
};

export const Presenter: React.FC<Props> = (props: Props) => {
  const { cow } = props;

  return (
    <div className="flex flex-col h-screen">
      <Navbar />
      <div className="flex flex-col justify-center lg:w-2/3 mx-4 lg:mx-auto">
        <div className="flex justify-between">
          <SectionTitle
            svgIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M4.5 3.75a3 3 0 00-3 3v10.5a3 3 0 003 3h15a3 3 0 003-3V6.75a3 3 0 00-3-3h-15zm4.125 3a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm-3.873 8.703a4.126 4.126 0 017.746 0 .75.75 0 01-.351.92 7.47 7.47 0 01-3.522.877 7.47 7.47 0 01-3.522-.877.75.75 0 01-.351-.92zM15 8.25a.75.75 0 000 1.5h3.75a.75.75 0 000-1.5H15zM14.25 12a.75.75 0 01.75-.75h3.75a.75.75 0 010 1.5H15a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3.75a.75.75 0 000-1.5H15z"
                  clipRule="evenodd"
                />
              </svg>
            }
            title="個体識別番号"
          />
          <div className="btn btn-accent btn-outline btn-sm w-fit mt-8 mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M13.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L11.69 12 4.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M19.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06L17.69 12l-6.97-6.97a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
                clipRule="evenodd"
              />
            </svg>
            <a href={`/daily_reports/${cow?.optional_id || ''}`}>診察結果</a>
          </div>
        </div>
        <EarTag cowsOptionalId={cow?.optional_id || ''} />
      </div>
      <CowsForm formType="update" cow={cow} />
      <PedigreeForm cow={cow} />
      <Footer />
    </div>
  );
};
