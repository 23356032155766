/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useEffect } from 'react';
import {
  DailyReportDetails as DailyReportDetailsData,
  Prescriptions as PrescriptionsData,
} from '../../../API';
import { DailyReportDetailsParams } from '../../../SharedTypes';
import { Alert, InputBadge } from '../../../components';

type Props = {
  dailyReportsId: string | undefined;
  editDailyReportDetail: DailyReportDetailsData | undefined;
  prescriptions: PrescriptionsData[];
  submitDailyReportDetails: (params: DailyReportDetailsParams) => void;
};

export const Presenter: React.FC<Props> = (props: Props) => {
  const {
    dailyReportsId,
    editDailyReportDetail,
    prescriptions,
    submitDailyReportDetails,
  } = props;

  const [params, setParams] = useState<DailyReportDetailsParams>({
    prescriptionsID: '',
  });
  const [options, setOptions] = useState<any[]>([]);
  const [alert, setAlert] = useState({ text: '', is: false });

  /**
   * Selectの変更時のハンドラ
   * @param e
   */
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setParams({ ...params, [e.target.name]: e.target.value });
  };

  /**
   * Input変更時のハンドラ
   * @param e
   */
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setParams({ ...params, [e.target.name]: e.target.value });
  };

  /**
   * バリデーションチェック
   * @returns
   */
  const isValid = (): boolean => {
    // params.prescriptionsIDのチェック
    if (!params.prescriptionsID) {
      setAlert({
        ...alert,
        text: '技術・薬品を選択してください。',
        is: true,
      });
      return false;
    }

    // params.aPointのチェック
    if (params.aPoint) {
      const regex = /^[0-9]+$/;
      if (!regex.test(params.aPoint.toString())) {
        setAlert({
          ...alert,
          text: 'A点は半角数字で入力してください。',
          is: true,
        });
        return false;
      }
    }

    // params.bPointのチェック
    if (params.bPoint) {
      const regex = /^[0-9]+$/;
      if (!regex.test(params.bPoint.toString())) {
        setAlert({
          ...alert,
          text: 'B点は半角数字で入力してください。',
          is: true,
        });
        return false;
      }
    }

    // params.usageAmountのチェック
    if (params.usageAmount) {
      const regex = /^[a-zA-Z0-9ぁ-んァ-ヶー一-龠]*$/;
      if (!regex.test(params.usageAmount)) {
        setAlert({
          ...alert,
          text: '使用量は半角英数字・ひらがな・カタカナ・漢字で入力してください。',
          is: true,
        });
        return false;
      }
    }

    // params.unitPriceのチェック
    if (params.unitPrice) {
      const regex = /^[-+]?(\d+(\.\d*)?|\.\d+)([eE][-+]?\d+)?$/;
      if (!regex.test(params.unitPrice.toString())) {
        setAlert({
          ...alert,
          text: '薬価は整数または小数で入力してください。',
          is: true,
        });
        return false;
      }
    }

    setAlert({ ...alert, text: '', is: false });
    return true;
  };

  /**
   * 送信ボタン押下時のハンドラ
   */
  const handleSubmitClick = () => {
    // バリデーションチェック
    if (!isValid()) {
      return;
    }

    // 診察結果詳細の登録・更新
    submitDailyReportDetails(params);

    // paramsの初期化
    setParams({ prescriptionsID: '' });
  };

  /**
   * dailyReportsIdが更新されたらStateを更新
   */
  useEffect(() => {
    // paramsの初期化
    setParams({ prescriptionsID: '' });
  }, [dailyReportsId]);

  /**
   * editDailyReportDetailが更新されたらStateを更新
   */
  useEffect(() => {
    // editDailyReportDetailが存在しない場合
    if (!editDailyReportDetail) {
      // paramsの初期化
      setParams({ prescriptionsID: '' });
      return;
    }

    // editDailyReportDetailが存在する場合
    setParams({
      prescriptionsID: editDailyReportDetail.prescriptionsID,
      aPoint: editDailyReportDetail?.a_point,
      bPoint: editDailyReportDetail?.b_point,
      usageAmount: editDailyReportDetail?.usage_amount,
      unitPrice: editDailyReportDetail?.unit_price,
    });
  }, [editDailyReportDetail]);

  /**
   * prescriptionsが更新されたらStateを更新
   */
  useEffect(() => {
    // Select用のOptionsを設定
    setOptions(
      prescriptions.map((prescription: PrescriptionsData) => (
        <option value={prescription.id} key={prescription.name}>
          {prescription.optional_id}： {prescription.name}
        </option>
      )),
    );
  }, [prescriptions]);

  /**
   * paramsが更新されたらStateを更新
   */
  useEffect(() => {
    // アラート表示をリセット
    setAlert({ ...alert, text: '', is: false });
  }, [params]);

  return (
    <>
      <Alert alertText={alert.text} visible={alert.is} />
      <div className="lg:flex lg:flex-wrap items-end mb-6 lg:gap-x-4">
        <div className="form-control max-w-xl">
          <label className="label">
            <span className="label-text">
              技術・薬品
              <InputBadge mark="必須" />
            </span>
          </label>
          <select
            className={`select select-bordered select-sm ${
              editDailyReportDetail ? 'select-secondary' : ''
            }`}
            value={params.prescriptionsID ?? ''}
            name="prescriptionsID"
            onChange={handleSelectChange}
          >
            <option value="">選択してください</option>
            {options}
          </select>
        </div>
        <div className="form-control max-w-xl lg:w-24">
          <label className="label">
            <span className="label-text">
              A点
              <InputBadge mark="任意" />
            </span>
          </label>
          <input
            type="text"
            placeholder="10"
            className={`input input-bordered input-sm ${
              editDailyReportDetail ? 'input-secondary' : ''
            }`}
            name="aPoint"
            value={params.aPoint ?? ''}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-control max-w-xl lg:w-24">
          <label className="label">
            <span className="label-text">
              B点
              <InputBadge mark="任意" />
            </span>
          </label>
          <input
            type="text"
            placeholder="20"
            className={`input input-bordered input-sm ${
              editDailyReportDetail ? 'input-secondary' : ''
            }`}
            name="bPoint"
            value={params.bPoint ?? ''}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-control max-w-xl lg:w-32">
          <label className="label">
            <span className="label-text">
              使用量
              <InputBadge mark="任意" />
            </span>
          </label>
          <input
            type="text"
            placeholder="20ml"
            className={`input input-bordered input-sm ${
              editDailyReportDetail ? 'input-secondary' : ''
            }`}
            name="usageAmount"
            value={params.usageAmount ?? ''}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-control max-w-xl lg:w-24">
          <label className="label">
            <span className="label-text">
              薬価
              <InputBadge mark="任意" />
            </span>
          </label>
          <input
            type="text"
            placeholder="300"
            className={`input input-bordered input-sm ${
              editDailyReportDetail ? 'input-secondary' : ''
            }`}
            name="unitPrice"
            value={params.unitPrice ?? ''}
            onChange={handleInputChange}
          />
        </div>
        <button
          className="btn btn-accent btn-sm w-full max-w-xl lg:w-fit mt-6"
          onClick={handleSubmitClick}
        >
          送信
        </button>
      </div>
    </>
  );
};
