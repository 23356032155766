import { useEffect } from 'react';
import { Presenter } from './Presenter';

type Props = {
  toastText: string;
  toastType: 'info' | 'success' | 'warning' | 'error';
  visible: boolean;
  hideToast: () => void;
};

export const Toast: React.FC<Props> = (props: Props) => {
  const { toastText, toastType, visible, hideToast } = props;

  useEffect(() => {
    if (visible) {
      window.setTimeout(() => {
        hideToast();
      }, 3000);
    }
  }, [visible]);

  return (
    <Presenter toastText={toastText} toastType={toastType} visible={visible} />
  );
};
