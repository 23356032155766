import { ColumnDef } from '@tanstack/react-table';
import { Presenter } from './Presenter';

type Props = {
  data: any[];
  columns: ColumnDef<any, any>[];
  size?: string;
};

export const Table: React.FC<Props> = (props: Props) => {
  const { data, columns, size } = props;

  return <Presenter data={data} columns={columns} size={size} />;
};
