import { Presenter } from './Presenter';

type Props = {
  svgIcon: JSX.Element;
  title: string;
};

export const SectionTitle: React.FC<Props> = (props: Props) => {
  const { title, svgIcon } = props;

  return <Presenter svgIcon={svgIcon} title={title} />;
};
