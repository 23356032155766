import { Presenter } from './Presenter';

type Props = {
  name: string;
  value?: string;
  disabled?: boolean;
  onChange: (date: Date) => void;
  inputSize?: string;
  inputColor?: string;
};

export const DatepickerInput: React.FC<Props> = (props: Props) => {
  const { name, value, disabled, onChange, inputSize, inputColor } = props;

  return (
    <Presenter
      name={name}
      value={value}
      disabled={disabled}
      onChange={onChange}
      inputSize={inputSize}
      inputColor={inputColor}
    />
  );
};
