/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useEffect, useRef } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import {
  DailyReportDetails as DailyReportDetailsData,
  Prescriptions as PrescriptionsData,
} from '../../../API';
import {
  DeleteDialog,
  SectionTitle,
  Table,
  DailyReportDetailsForm,
} from '../../../components';

type Props = {
  dailyReportsId: string | undefined;
  dailyReportDetails: DailyReportDetailsData[];
  setDailyReportDetails: React.Dispatch<DailyReportDetailsData[]>;
  prescriptions: PrescriptionsData[];
};

// 子コンポーネントDailyReportDetailsFormのメソッドを定義
interface DailyReportDetailsFormMethods {
  deleteDailyReportDetails: () => void;
}

export const Presenter: React.FC<Props> = (props: Props) => {
  const {
    dailyReportsId,
    dailyReportDetails,
    setDailyReportDetails,
    prescriptions,
  } = props;

  const [data, setData] = useState<DailyReportDetailsData[]>([]);
  const [editDailyReportDetail, setEditDailyReportDetail] =
    useState<DailyReportDetailsData>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dailyReportDetailsFormRef =
    useRef<DailyReportDetailsFormMethods | null>(null);

  /**
   * 編集ボタン押下時の処理
   * @param e
   */
  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const rowId = e.currentTarget.value;
    const row = dailyReportDetails.find((detail) => detail.id === rowId);
    setEditDailyReportDetail(row);
  };

  /**
   * 削除ボタン押下時の処理
   * @param e
   */
  const handleDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsModalOpen(true);

    const rowId = e.currentTarget.value;
    const row = dailyReportDetails.find((detail) => detail.id === rowId);
    setEditDailyReportDetail(row);
  };

  /**
   * 削除確認ダイアログで「削除」を押下時の処理
   */
  const deleteDailyReportDetails = () => {
    setIsModalOpen(false);

    // 子コンポーネントDailyReportDetailsFormのメソッドを実行
    if (dailyReportDetailsFormRef.current) {
      dailyReportDetailsFormRef.current.deleteDailyReportDetails();
    }
  };

  /**
   * テーブルのカラム設定
   */
  const columns: ColumnDef<{}, any>[] = [
    {
      accessorKey: 'id',
      header: '編集・削除',
      cell: (row) => (
        <div className="flex gap-2">
          <button
            className="btn btn-sm btn-secondary btn-square"
            value={row.getValue()}
            onClick={handleEditClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-4 h-4"
            >
              <path d="M2.695 14.763l-1.262 3.154a.5.5 0 00.65.65l3.155-1.262a4 4 0 001.343-.885L17.5 5.5a2.121 2.121 0 00-3-3L3.58 13.42a4 4 0 00-.885 1.343z" />
            </svg>
          </button>
          <button
            className="btn btn-sm btn-square"
            value={row.getValue()}
            onClick={handleDeleteClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-4 h-4"
            >
              <path
                fillRule="evenodd"
                d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      ),
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      accessorKey: 'prescriptionsID',
      header: '技術・薬品',
      cell: (row) => {
        const target = prescriptions.find(
          (prescription) => prescription.id === row.getValue(),
        );
        return `${target?.optional_id || ''}： ${target?.name || ''}`;
      },
      enableColumnFilter: false,
    },
    {
      accessorKey: 'a_point',
      header: 'A点',
      enableColumnFilter: false,
    },
    {
      accessorKey: 'b_point',
      header: 'B点',
      enableColumnFilter: false,
    },
    {
      accessorKey: 'usage_amount',
      header: '使用量',
      enableColumnFilter: false,
    },
    {
      accessorKey: 'unit_price',
      header: '薬価',
      enableColumnFilter: false,
    },
  ];

  /**
   * dailyReportDetailsが更新されたらStateを更新
   */
  useEffect(() => {
    // テーブルデータを設定
    setData(dailyReportDetails);
  }, [dailyReportDetails]);

  /**
   * dailyReportsIdが更新されたらStateを更新
   */
  useEffect(() => {
    // 編集中の診察結果詳細をリセット
    setEditDailyReportDetail(undefined);
  }, [dailyReportsId]);

  return (
    <div className="flex flex-col">
      <div className="flex items-end mb-2 gap-x-2">
        <SectionTitle
          svgIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M10.5 3.798v5.02a3 3 0 01-.879 2.121l-2.377 2.377a9.845 9.845 0 015.091 1.013 8.315 8.315 0 005.713.636l.285-.071-3.954-3.955a3 3 0 01-.879-2.121v-5.02a23.614 23.614 0 00-3 0zm4.5.138a.75.75 0 00.093-1.495A24.837 24.837 0 0012 2.25a25.048 25.048 0 00-3.093.191A.75.75 0 009 3.936v4.882a1.5 1.5 0 01-.44 1.06l-6.293 6.294c-1.62 1.621-.903 4.475 1.471 4.88 2.686.46 5.447.698 8.262.698 2.816 0 5.576-.239 8.262-.697 2.373-.406 3.092-3.26 1.47-4.881L15.44 9.879A1.5 1.5 0 0115 8.818V3.936z"
                clipRule="evenodd"
              />
            </svg>
          }
          title="技術・薬品"
        />
      </div>
      {dailyReportsId ? (
        <DailyReportDetailsForm
          ref={dailyReportDetailsFormRef}
          dailyReportsId={dailyReportsId}
          editDailyReportDetail={editDailyReportDetail}
          setEditDailyReportDetail={setEditDailyReportDetail}
          setDailyReportDetails={setDailyReportDetails}
        />
      ) : (
        <div className="text-sm mb-4">
          <p className="my-1">
            診察結果を送信後、技術・薬品を登録することができます。
          </p>
        </div>
      )}
      <Table data={data} columns={columns} size="table-sm" />
      <DeleteDialog
        dataName="技術・薬品"
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onDeleteClick={deleteDailyReportDetails}
      />
    </div>
  );
};
