/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { DailyReports as DailyReportsData } from '../../API';
import {
  Footer,
  Navbar,
  SectionTitle,
  Table,
  EarTag,
  DailyReportForm,
} from '../../components';

type Props = {
  cowsId: string | undefined;
  cowsOptionalId: string | undefined;
  dailyReports: DailyReportsData[];
  setDailyReports: React.Dispatch<React.SetStateAction<DailyReportsData[]>>;
};

export const Presenter: React.FC<Props> = (props: Props) => {
  const { cowsId, cowsOptionalId, dailyReports, setDailyReports } = props;

  const [examDate, setExamDate] = useState<string>();

  /**
   * 表示ボタン押下時のハンドラ
   * @param event
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExamDate(event.currentTarget.value);

    // dailyreportformまでスクロール
    const targetElement = document.getElementById('dailyreportform');
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  /**
   * テーブルのカラム設定
   */
  const columns: ColumnDef<{}, any>[] = [
    {
      id: 'select', // idを指定しないとソートができない
      accessorKey: 'exam_date',
      header: '診察日',
      enableColumnFilter: false,
      sortDescFirst: true,
    },
    {
      accessorKey: 'recorded_by',
      header: '記入者',
    },
    {
      accessorKey: 'exam_record',
      header: '診察記録',
      cell: (row) =>
        `${row.getValue()}`.length > 30
          ? `${`${row.getValue()}`.slice(0, 30)} ...`
          : `${row.getValue()}`,
    },
    {
      accessorKey: 'exam_date',
      header: '',
      cell: (row) => (
        <button
          className="btn btn-secondary btn-sm"
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          value={row.getValue()}
          onClick={handleClick}
        >
          表示
        </button>
      ),
      enableSorting: false,
      enableColumnFilter: false,
    },
  ];

  return (
    <div className="flex flex-col h-screen">
      <Navbar />
      <div className="flex flex-col justify-center lg:w-2/3 mx-4 lg:mx-auto">
        <div className="flex justify-between">
          <SectionTitle
            svgIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M4.5 3.75a3 3 0 00-3 3v10.5a3 3 0 003 3h15a3 3 0 003-3V6.75a3 3 0 00-3-3h-15zm4.125 3a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm-3.873 8.703a4.126 4.126 0 017.746 0 .75.75 0 01-.351.92 7.47 7.47 0 01-3.522.877 7.47 7.47 0 01-3.522-.877.75.75 0 01-.351-.92zM15 8.25a.75.75 0 000 1.5h3.75a.75.75 0 000-1.5H15zM14.25 12a.75.75 0 01.75-.75h3.75a.75.75 0 010 1.5H15a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3.75a.75.75 0 000-1.5H15z"
                  clipRule="evenodd"
                />
              </svg>
            }
            title="個体識別番号"
          />
          <div className="btn btn-accent btn-outline btn-sm w-fit mt-8 mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M13.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L11.69 12 4.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M19.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06L17.69 12l-6.97-6.97a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
                clipRule="evenodd"
              />
            </svg>
            <a href={`/cows/${cowsOptionalId}`}>個体情報詳細</a>
          </div>
        </div>
        <EarTag cowsOptionalId={cowsOptionalId || ''} />
      </div>
      <DailyReportForm
        cowsId={cowsId}
        examDate={examDate}
        setExamDate={setExamDate}
        setDailyReports={setDailyReports}
      />
      <div
        id="dailyreports"
        className="flex flex-col justify-center lg:w-2/3 mx-4 lg:mx-auto"
      >
        <SectionTitle
          svgIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M7.502 6h7.128A3.375 3.375 0 0118 9.375v9.375a3 3 0 003-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 00-.673-.05A3 3 0 0015 1.5h-1.5a3 3 0 00-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6zM13.5 3A1.5 1.5 0 0012 4.5h4.5A1.5 1.5 0 0015 3h-1.5z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V9.375zM6 12a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H6.75a.75.75 0 01-.75-.75V12zm2.25 0a.75.75 0 01.75-.75h3.75a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM6 15a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H6.75a.75.75 0 01-.75-.75V15zm2.25 0a.75.75 0 01.75-.75h3.75a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM6 18a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H6.75a.75.75 0 01-.75-.75V18zm2.25 0a.75.75 0 01.75-.75h3.75a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75z"
                clipRule="evenodd"
              />
            </svg>
          }
          title="診察結果一覧"
        />
        <Table data={dailyReports} columns={columns} />
      </div>
      <Footer />
    </div>
  );
};
