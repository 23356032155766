/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import {
  MovementHistory,
  ModelMovementHistoryFilterInput,
  ListMovementHistoriesQuery,
  ListMovementHistoriesQueryVariables,
  CreateMovementHistoryInput,
  CreateMovementHistoryMutation,
  UpdateMovementHistoryInput,
  UpdateMovementHistoryMutation,
  DeleteMovementHistoryInput,
  DeleteMovementHistoryMutation
} from '../API';

/**
 * GraphQLでcowsIdをキーにMovementHistory一覧を取得
 */
export const fetchMovementHistoriesByCowsId = async (
  cowsId: string | undefined,
): Promise<MovementHistory[]> => {
  if (!cowsId) {
    return [];
  }

  const allItems: MovementHistory[] = [];
  let nextToken: string | undefined | null = null;
  const filter: ModelMovementHistoryFilterInput = {
    and: [
      { cowsID: { eq: cowsId } },
      { _deleted: { ne: true } }
    ]
  };

  do {
    const variables: ListMovementHistoriesQueryVariables = {
      filter,
      limit: 20,
      nextToken,
    };

    // eslint-disable-next-line no-await-in-loop
    const response = await API.graphql<GraphQLQuery<ListMovementHistoriesQuery>>({
      query: queries.listMovementHistories,
      variables,
    });

    // @ts-ignore
    const { items, nextToken: newNextToken } = response.data?.listMovementHistories || {
      items: [],
      nextToken: null,
    };

    allItems.push(...items);
    nextToken = newNextToken;
  } while (nextToken);

  return allItems;
};

/**
 * GraphQLでMovementHistoryに登録
 */
export const createMovementHistory = async (
  details: CreateMovementHistoryInput,
): Promise<MovementHistory> => {
  const item = await API.graphql<GraphQLQuery<CreateMovementHistoryMutation>>(
    graphqlOperation(mutations.createMovementHistory, { input: details })
  );

  // @ts-ignore
  return item.data.createMovementHistory;
};

/**
 * GraphQLでMovementHistoryを更新
 */
export const updateMovementHistory = async (
  details: UpdateMovementHistoryInput
): Promise<void> => {
  await API.graphql<GraphQLQuery<UpdateMovementHistoryMutation>>({
    query: mutations.updateMovementHistory,
    variables: { input: details },
  });
};

/**
 * GraphQLでMovementHistoryからデータを削除
 */
export const deleteMovementHistory = async (details: DeleteMovementHistoryInput)  => {
  await API.graphql<GraphQLQuery<DeleteMovementHistoryMutation>>({ 
    query: mutations.deleteMovementHistory, 
    variables: { input: details }
  });
};
