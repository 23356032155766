/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useEffect, useState } from 'react';
import * as apis from '../../../apis';
import {
  CreateDailyReportsInput,
  DailyReports as DailyReportsData,
  UpdateDailyReportsInput,
} from '../../../API';
import { useToast } from '../../../contexts';
import { DailyReportsParams } from '../../../SharedTypes';
import { Presenter } from './Presenter';

type Props = {
  cowsId: string | undefined;
  examDate: string | undefined;
  setExamDate: React.Dispatch<React.SetStateAction<string | undefined>>;
  setDailyReports: React.Dispatch<React.SetStateAction<DailyReportsData[]>>;
};

export const DailyReportForm: React.FC<Props> = (props: Props) => {
  const { cowsId, examDate, setExamDate, setDailyReports } = props;

  const [dailyReport, setDailyReport] = useState<DailyReportsData>();

  const showToast = useToast();

  /**
   * 現在時刻をYYYY-MM-DD形式に整形
   */
  const getCurrentDateInJST = (): string => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  /**
   * 日付をキーに診察結果を取得
   * @param date
   */
  const getDailyReportByExamDate = (date: string) => {
    if (!cowsId) {
      return;
    }

    apis
      .fetchDailyReportsByExamDate(cowsId, date)
      .then((result) => {
        setDailyReport(result.length > 0 ? result[0] : undefined);
      })
      .catch((error) => {
        showToast({
          text: `データ取得に失敗しました。${JSON.stringify(error)}`,
          type: 'error',
        });
      });
  };

  /**
   * 送信ボタン押下時のAPI実行処理
   * @param optionalId
   */
  const submitDailyReports = (params: DailyReportsParams) => {
    if (!cowsId) {
      return;
    }

    // dailyReport.idがあれば更新、なければ登録
    if (dailyReport?.id) {
      // データ更新用パラメータ
      const details: UpdateDailyReportsInput = {
        id: dailyReport.id,
        exam_date: params.examDate,
        recorded_by: params.recordedBy,
        exam_record: params.examRecord,
        photo1: params.photo1,
        photo2: params.photo2,
        _version: dailyReport._version,
      };

      // データ更新
      apis
        .updateDailyReports(details)
        .then(() => {
          showToast({
            text: `データを更新しました。`,
            type: 'success',
          });

          // 成功したら個体情報一覧を再取得する
          apis
            .fetchDailyReportsByCowsId(cowsId)
            .then((result) => {
              setDailyReports(result);
            })
            .catch((error) => {
              showToast({
                text: `データ取得に失敗しました。${JSON.stringify(error)}`,
                type: 'error',
              });
            });
        })
        .catch((error) => {
          showToast({
            text: `データ更新に失敗しました。${JSON.stringify(error)}`,
            type: 'error',
          });
        });
    } else {
      // データ登録用パラメータ
      const details: CreateDailyReportsInput = {
        cowsID: cowsId,
        exam_date: params.examDate,
        recorded_by: params.recordedBy,
        exam_record: params.examRecord,
        photo1: params.photo1,
        photo2: params.photo2,
      };

      // データ登録
      apis
        .createDailyReports(details)
        .then((result) => {
          setDailyReport(result);
          showToast({
            text: `データを登録しました。`,
            type: 'success',
          });

          // 成功したら診察結果一覧を再取得する
          apis
            .fetchDailyReportsByCowsId(cowsId)
            .then((list) => {
              setDailyReports(list);
            })
            .catch((error) => {
              showToast({
                text: `データ取得に失敗しました。${JSON.stringify(error)}`,
                type: 'error',
              });
            });
        })
        .catch((error) => {
          showToast({
            text: `データ更新に失敗しました。${JSON.stringify(error)}`,
            type: 'error',
          });
        });
    }
  };

  /**
   * cowsIdまたはexamDateが更新されたら診察結果を取得
   */
  useEffect(() => {
    const date = examDate || getCurrentDateInJST();
    getDailyReportByExamDate(date);
  }, [cowsId, examDate]);

  return (
    <Presenter
      cowsId={cowsId}
      dailyReport={dailyReport}
      setExamDate={setExamDate}
      submitDailyReports={submitDailyReports}
    />
  );
};
