/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useState, useEffect } from 'react';
import { Cows as CowsData } from '../../../API';
import { PedigreeParams } from '../../../SharedTypes';

type Props = {
  name: string;
  params: {
    [key: string]: PedigreeParams;
  };
  cows: CowsData[];
  getParentPedigreeData: (name: string, value: string) => void;
  setChildPedigreeData: (name: string, value: string) => void;
};

export const Presenter: React.FC<Props> = (props: Props) => {
  const { name, params, cows, getParentPedigreeData, setChildPedigreeData } =
    props;

  const [inputValue, setInputValue] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [tooltip, setTooltip] = useState<{
    text: string;
    visible: boolean;
  }>({
    text: '',
    visible: false,
  });

  /**
   * バリデーションチェック
   * @param optionalId
   * @returns
   */
  const isValid = (optionalId: string): boolean => {
    // 空文字の場合はtrueを返却
    if (optionalId.length === 0) {
      return true;
    }

    // 空文字以外のfalsyな値はfalseを返却
    if (!optionalId) {
      return false;
    }

    // 10桁の数字以外はfalseを返却
    const regexOptionalId = /^\d{10}$/;
    if (!regexOptionalId.test(optionalId)) {
      return false;
    }
    return true;
  };

  /**
   * 子の血統情報が存在するかチェック
   * @returns
   */
  const hasChildPedigreeData = (): boolean => {
    // 子の世代
    const childGeneration = String.fromCharCode(name.charCodeAt(0) - 1);

    // 自身が子のどちらの親か
    const parent = name.substring(name.lastIndexOf('-') + 1);

    // 子のname属性
    const childName = `${
      childGeneration + name.slice(1).replace(new RegExp(`-${parent}$`), '')
    }`;

    // 子の血統情報がない場合はfalseを返却
    if (!params[childName].optionalId) {
      return false;
    }
    return true;
  };

  /**
   * 入力値のoptionalIdが個体情報を持っているかチェック
   * @returns
   */
  const hasCowsData = () => cows.some((cow) => cow.optional_id === inputValue);

  /**
   * Input変更時のハンドラ
   * @param event
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setInputValue(value);
  };

  /**
   * Input入力後のハンドラ
   * @param event
   */
  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (!isValid(value)) {
      setTooltip({
        ...tooltip,
        text: '10桁の半角数字で入力してください。',
        visible: true,
      });
    } else {
      setTooltip({ ...tooltip, text: '', visible: false });
    }
  };

  /**
   * 編集ボタンクリック時のハンドラ
   */
  const handleEditClick = () => {
    if (!hasChildPedigreeData()) {
      setTooltip({
        ...tooltip,
        text: '子の血統情報を先に登録してください。',
        visible: true,
      });
      return;
    }

    setIsEdit(true);
    setTooltip({ ...tooltip, text: '', visible: false });
  };

  /**
   * 送信ボタンクリック時のハンドラ
   */
  const handleSendClick = () => {
    // バリデーションチェック
    if (!isValid(inputValue)) {
      return;
    }
    setIsEdit(false);
    setTooltip({ ...tooltip, text: '', visible: false });

    // 自身と親の血統情報を取得
    getParentPedigreeData(name, inputValue);
    // 自身と子の血統情報を設定
    setChildPedigreeData(name, inputValue);
  };

  /**
   * キャンセルボタンクリック時のハンドラ
   */
  const handleCancelClick = () => {
    setInputValue(params[name].optionalId);
    setIsEdit(false);
    setTooltip({ ...tooltip, text: '', visible: false });
  };

  /**
   * params[name]が更新されたらStateを更新
   */
  useEffect(() => {
    setInputValue(params[name]?.optionalId || '');
    setTooltip({ ...tooltip, text: '', visible: false });
  }, [params[name]]);

  return (
    <div className="form-control w-full max-w-xs">
      <div className="flex">
        <div
          className={
            tooltip.visible
              ? 'tooltip tooltip-warning tooltip-open tooltip-top'
              : ''
          }
          data-tip={tooltip.text}
        >
          {isEdit ? (
            <input
              type="text"
              name={name}
              className="input input-bordered input-secondary input-sm w-32"
              onChange={handleChange}
              onBlur={handleBlur}
              value={inputValue}
              list="optionalIdList"
            />
          ) : (
            <div className="flex items-center bg-base-200 rounded h-8 w-32 px-3">
              {hasCowsData() ? (
                <a
                  className="underline underline-offset-1 text-accent font-bold"
                  href={`/cows/${inputValue}`}
                >
                  {inputValue}
                </a>
              ) : (
                <div>{inputValue}</div>
              )}
            </div>
          )}
        </div>
        {isEdit ? (
          <>
            <button
              className="btn btn-sm btn-accent btn-square ml-1"
              onClick={handleSendClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M3.105 2.288a.75.75 0 0 0-.826.95l1.414 4.926A1.5 1.5 0 0 0 5.135 9.25h6.115a.75.75 0 0 1 0 1.5H5.135a1.5 1.5 0 0 0-1.442 1.086l-1.414 4.926a.75.75 0 0 0 .826.95 28.897 28.897 0 0 0 15.293-7.155.75.75 0 0 0 0-1.114A28.897 28.897 0 0 0 3.105 2.288Z" />
              </svg>
            </button>
            <button
              className="btn btn-sm btn-square ml-1"
              onClick={handleCancelClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
              </svg>
            </button>
          </>
        ) : (
          <button
            className="btn btn-sm btn-secondary btn-square ml-1"
            onClick={handleEditClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path d="m2.695 14.762-1.262 3.155a.5.5 0 0 0 .65.65l3.155-1.262a4 4 0 0 0 1.343-.886L17.5 5.501a2.121 2.121 0 0 0-3-3L3.58 13.419a4 4 0 0 0-.885 1.343Z" />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};
