import { Prescriptions as PrescriptionsData } from '../../API';
import { Footer, Navbar, PrescriptionsForm } from '../../components';

type Props = {
  prescription: PrescriptionsData | undefined;
};

export const Presenter: React.FC<Props> = (props: Props) => {
  const { prescription } = props;

  return (
    <div className="flex flex-col h-screen">
      <Navbar />
      <PrescriptionsForm formType="update" prescription={prescription} />
      <Footer />
    </div>
  );
};
