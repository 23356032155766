import { Presenter } from './Presenter';

type Props = {
  alertText: string;
  visible: boolean;
};

export const Alert: React.FC<Props> = (props: Props) => {
  const { alertText, visible } = props;

  return <Presenter alertText={alertText} visible={visible} />;
};
