/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useEffect, useRef } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { MovementHistory as MovementHistoryData } from '../../../API';
import {
  DeleteDialog,
  SectionTitle,
  Table,
  MovementHistoryForm,
} from '../../../components';

type Props = {
  cowsId: string | undefined;
  movementHistories: MovementHistoryData[];
  setMovementHistories: React.Dispatch<MovementHistoryData[]>;
};

// 子コンポーネントMovementHistoryFormのメソッドを定義
interface MovementHistoryFormMethods {
  deleteMovementHistory: () => void;
}

export const Presenter: React.FC<Props> = (props: Props) => {
  const { cowsId, movementHistories, setMovementHistories } = props;

  const [data, setData] = useState<MovementHistoryData[]>([]);
  const [editMovementHistory, setEditMovementHistory] =
    useState<MovementHistoryData>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const movementHistoryFormRef = useRef<MovementHistoryFormMethods | null>(
    null,
  );

  /**
   * 編集ボタン押下時の処理
   * @param e
   */
  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const rowId = e.currentTarget.value;
    const row = movementHistories.find((detail) => detail.id === rowId);
    setEditMovementHistory(row);
  };

  /**
   * 削除ボタン押下時の処理
   * @param e
   */
  const handleDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsModalOpen(true);

    const rowId = e.currentTarget.value;
    const row = movementHistories.find((detail) => detail.id === rowId);
    setEditMovementHistory(row);
  };

  /**
   * 削除確認ダイアログで「削除」を押下時の処理
   */
  const deleteMovementHistory = () => {
    setIsModalOpen(false);

    // 子コンポーネントMovementHistoryFormのメソッドを実行
    if (movementHistoryFormRef.current) {
      movementHistoryFormRef.current.deleteMovementHistory();
    }
  };

  /**
   * テーブルのカラム設定
   */
  const columns: ColumnDef<{}, any>[] = [
    {
      accessorKey: 'id',
      header: '編集・削除',
      cell: (row) => (
        <div className="flex gap-2">
          <button
            className="btn btn-sm btn-secondary btn-square"
            value={row.getValue()}
            onClick={handleEditClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-4 h-4"
            >
              <path d="M2.695 14.763l-1.262 3.154a.5.5 0 00.65.65l3.155-1.262a4 4 0 001.343-.885L17.5 5.5a2.121 2.121 0 00-3-3L3.58 13.42a4 4 0 00-.885 1.343z" />
            </svg>
          </button>
          <button
            className="btn btn-sm btn-square"
            value={row.getValue()}
            onClick={handleDeleteClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-4 h-4"
            >
              <path
                fillRule="evenodd"
                d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      ),
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      accessorKey: 'location',
      header: '牧場',
      enableColumnFilter: false,
    },
    {
      accessorKey: 'move_date',
      header: '移動日',
      enableColumnFilter: false,
    },
    {
      accessorKey: 'reason',
      header: '移動理由',
      enableColumnFilter: false,
    },
  ];

  /**
   * movementHistoriesが更新されたらStateを更新
   */
  useEffect(() => {
    // テーブルデータを設定
    setData(movementHistories);
  }, [movementHistories]);

  /**
   * cowsIdが更新されたらStateを更新
   */
  useEffect(() => {
    // 編集中の移動履歴をリセット
    setEditMovementHistory(undefined);
  }, [cowsId]);

  return (
    <div className="flex flex-col">
      <div className="flex items-end mb-2 gap-x-2">
        <SectionTitle
          svgIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path d="M3.375 4.5C2.339 4.5 1.5 5.34 1.5 6.375V13.5h12V6.375c0-1.036-.84-1.875-1.875-1.875h-8.25ZM13.5 15h-12v2.625c0 1.035.84 1.875 1.875 1.875h.375a3 3 0 1 1 6 0h3a.75.75 0 0 0 .75-.75V15Z" />
              <path d="M8.25 19.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0ZM15.75 6.75a.75.75 0 0 0-.75.75v11.25c0 .087.015.17.042.248a3 3 0 0 1 5.958.464c.853-.175 1.522-.935 1.464-1.883a18.659 18.659 0 0 0-3.732-10.104 1.837 1.837 0 0 0-1.47-.725H15.75Z" />
              <path d="M19.5 19.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
            </svg>
          }
          title="移動履歴"
        />
      </div>
      <MovementHistoryForm
        ref={movementHistoryFormRef}
        cowsId={cowsId}
        editMovementHistory={editMovementHistory}
        setEditMovementHistory={setEditMovementHistory}
        setMovementHistories={setMovementHistories}
      />
      <Table data={data} columns={columns} size="table-sm" />
      <DeleteDialog
        dataName="移動履歴"
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onDeleteClick={deleteMovementHistory}
      />
    </div>
  );
};
