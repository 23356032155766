import { Presenter } from './Presenter';

type Props = {
  cowsOptionalId: string;
};

export const EarTag: React.FC<Props> = (props: Props) => {
  const { cowsOptionalId } = props;

  return <Presenter cowsOptionalId={cowsOptionalId} />;
};
