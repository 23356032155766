import { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Alert, InputBadge } from '../../../components';
import { DailyReportsParams } from '../../../SharedTypes';

type Props = {
  label: string;
  paramName: string;
  params: DailyReportsParams;
  setParams: React.Dispatch<React.SetStateAction<DailyReportsParams>>;
  photoURL: string | undefined;
  getPhotoURL: (fileName: string) => Promise<void>;
  uploadPhoto: (fileName: string, file: File | undefined) => Promise<void>;
};

export const Presenter: React.FC<Props> = (props: Props) => {
  const {
    label,
    paramName,
    params,
    setParams,
    photoURL,
    getPhotoURL,
    uploadPhoto,
  } = props;

  const [alert, setAlert] = useState({ text: '', is: false });

  const fileInputRef = useRef<HTMLInputElement>(null);

  /**
   * UUIDを生成する
   * @returns UUID
   */
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  const generateUUID = (): string => uuidv4();

  /**
   * ファイルインプットのクリア
   */
  const handleFileInputClear = () => {
    if (!fileInputRef.current) {
      return;
    }

    fileInputRef.current.value = '';
  };

  /**
   * バリデーションチェック
   * @param name
   * @param type
   * @param size
   * @returns
   */
  const isValid = (name: string, type: string, size: number): boolean => {
    // ファイル名をチェック
    const regex = /[^/:*?"<>|\\ ]+/;
    if (!regex.test(name)) {
      setAlert({
        ...alert,
        text: 'ファイル名に不適切な文字（/:*?"<>|等）を含めないでください。',
        is: true,
      });
      return false;
    }

    // Content-Typeをチェック
    const allowedContentTypes = ['image/png', 'image/jpeg', 'image/gif'];
    if (allowedContentTypes.indexOf(type) === -1) {
      setAlert({
        ...alert,
        text: 'PNG・JPG・GIFのいずれかのファイルをアップロードしてください。',
        is: true,
      });
      return false;
    }

    // 最大ファイルサイズ（バイト単位）をチェック
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
    if (size > maxSizeInBytes) {
      setAlert({
        ...alert,
        text: 'ファイルサイズが大きすぎます。10MB未満のファイルをアップロードしてください。',
        is: true,
      });
      return false;
    }

    setAlert({ ...alert, text: '', is: false });
    return true;
  };

  /**
   * ファイルインプット変更時のハンドラ
   * @param event
   */
  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];

    const { name, type, size } = file;

    // バリデーションチェック
    if (!isValid(name, type, size)) {
      handleFileInputClear();
      return;
    }

    // 同ファイル名の場合に重複しないようファイル名の先頭にUUIDを付与
    const fileName = `${generateUUID()}_${name}`;

    // 画像をアップロード
    uploadPhoto(fileName, file)
      .then(() => {
        setParams({ ...params, [paramName]: fileName });

        // ファイルインプットのクリア
        handleFileInputClear();

        // アップロードした画像を表示
        getPhotoURL(fileName)
          .then(() => {})
          .catch(() => {});
      })
      .catch(() => {});
  };

  /**
   * paramsが更新されたらStateを更新
   */
  useEffect(() => {
    // アラート表示をリセット
    setAlert({ ...alert, text: '', is: false });

    // ファイルインプットのクリア
    handleFileInputClear();
  }, [params]);

  return (
    <div className="max-w-xl">
      <div className="form-control">
        <label className="label">
          <span className="label-text">
            {label}
            <InputBadge mark="任意" />
          </span>
        </label>
        <input
          type="file"
          className="file-input file-input-bordered file-input-sm w-full"
          name={paramName}
          onChange={handleFileInputChange}
          ref={fileInputRef}
        />
      </div>
      <Alert alertText={alert.text} visible={alert.is} />
      <div
        className={`flex flex-col items-center justify-center border-2 border-gray-300 border-dashed rounded-lg bg-gray-100 mt-3 mb-3 max-w-xl sm:max-w-xs ${
          !photoURL ? 'h-48' : ''
        }`}
      >
        {photoURL ? (
          <img className="rounded-lg" src={photoURL} />
        ) : (
          <div className="flex flex-col items-center text-xs">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6 text-gray-500 dark:text-gray-400"
            >
              <path
                fillRule="evenodd"
                d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                clipRule="evenodd"
              />
            </svg>
            PNG or JPG, GIF ( Max 10MB )
          </div>
        )}
      </div>
    </div>
  );
};
