/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import {
  Cows,
  ModelCowsFilterInput,
  ListCowsQuery,
  ListCowsQueryVariables,
  CreateCowsInput,
  CreateCowsMutation,
  UpdateCowsInput,
  UpdateCowsMutation,
  DeleteCowsMutation,
  DeleteCowsInput
} from '../API';

/**
 * GraphQLでCows一覧を取得
 */
export const fetchCows = async (): Promise<Cows[]> => {
  const allItems: Cows[] = [];
  let nextToken: string | undefined | null = null;
  const filter: ModelCowsFilterInput = { _deleted: { ne: true } };

  do {
    const variables: ListCowsQueryVariables = {
      filter,
      limit: 20,
      nextToken,
    };

    // eslint-disable-next-line no-await-in-loop
    const response = await API.graphql<GraphQLQuery<ListCowsQuery>>({
      query: queries.listCows,
      variables,
    });

    // @ts-ignore
    const { items, nextToken: newNextToken } = response.data?.listCows || {
      items: [],
      nextToken: null,
    };

    allItems.push(...items);
    nextToken = newNextToken;
  } while (nextToken);

    // optional_idでソートする比較関数
    const compareById = (a: Cows, b: Cows) => {
      if (a.optional_id && b.optional_id) {
        return a.optional_id.localeCompare(b.optional_id);
      }
      return 0;
    };
  
    // optional_idでソート
    allItems.sort(compareById);

  return allItems;
};

/**
 * GraphQLでOptionalIdをキーにCows一覧を取得
 */
export const fetchCowsByOptionalId = async (
  optionalId: string | undefined,
): Promise<Cows[]> => {
  if (!optionalId) {
    return [];
  }

  const allItems: Cows[] = [];
  let nextToken: string | undefined | null = null;
  const filter: ModelCowsFilterInput = {
    and: [
      { optional_id: { eq: optionalId } },
      { _deleted: { ne: true } }
    ]
  };

  do {
    const variables: ListCowsQueryVariables = {
      filter,
      limit: 20,
      nextToken,
    };

    // eslint-disable-next-line no-await-in-loop
    const response = await API.graphql<GraphQLQuery<ListCowsQuery>>({
      query: queries.listCows,
      variables,
    });

    // @ts-ignore
    const { items, nextToken: newNextToken } = response.data?.listCows || {
      items: [],
      nextToken: null,
    };

    allItems.push(...items);
    nextToken = newNextToken;
  } while (nextToken);

  return allItems;
};

/**
 * GraphQLでCowsに登録
 */
export const createCows = async (
  details: CreateCowsInput,
): Promise<Cows> => {
  const item = await API.graphql<GraphQLQuery<CreateCowsMutation>>(
    graphqlOperation(mutations.createCows, { input: details })
  );

  // @ts-ignore
  return item.data.createCows;
};

/**
 * GraphQLでCowsを更新
 */
export const updateCows = async (
  details: UpdateCowsInput
): Promise<void> => {
  await API.graphql<GraphQLQuery<UpdateCowsMutation>>({
    query: mutations.updateCows,
    variables: { input: details },
  });
};

/**
 * GraphQLでCowsからデータを削除
 */
export const deleteCows = async (details: DeleteCowsInput)  => {
  await API.graphql<GraphQLQuery<DeleteCowsMutation>>({ 
    query: mutations.deleteCows, 
    variables: { input: details }
  });
};
