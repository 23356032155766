import { useState, useEffect } from 'react';
import { Prescriptions as PrescriptionsData } from '../../../API';
import {
  Alert,
  DeleteDialog,
  InputBadge,
  SectionTitle,
} from '../../../components';
import { PrescriptionsParams } from '../../../SharedTypes';

type Props = {
  formType: 'create' | 'update';
  prescription?: PrescriptionsData;
  submitPrescriptions: (params: PrescriptionsParams) => void;
  deletePrescriptions: () => void;
};

export const Presenter: React.FC<Props> = (props: Props) => {
  const { formType, prescription, submitPrescriptions, deletePrescriptions } =
    props;

  const [params, setParams] = useState<PrescriptionsParams>({
    optionalId: '',
    name: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alert, setAlert] = useState({ text: '', is: false });

  /**
   * インプット変更時のハンドラ
   * @param event
   */
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setParams({ ...params, [event.target.name]: event.target.value });
  };

  /**
   * バリデーションチェック
   * @returns
   */
  const isValid = (): boolean => {
    // params.optionalIdのチェック
    if (params.optionalId.length === 0) {
      setAlert({
        ...alert,
        text: 'IDを入力してください。',
        is: true,
      });
      return false;
    }
    const regexOptionalId = /^[0-9a-zA-Z_-]+$/;
    if (!regexOptionalId.test(params.optionalId)) {
      setAlert({
        ...alert,
        text: 'IDは半角英数字・アンダースコア(_)・ハイフン(-)で入力してください',
        is: true,
      });
      return false;
    }

    // params.nameのチェック
    if (params.name.length === 0) {
      setAlert({
        ...alert,
        text: '技術・薬品名を入力してください。',
        is: true,
      });
      return false;
    }
    const regexName = /^[a-zA-Z0-9ぁ-んァ-ヶー一-龠 ]*$/;
    if (!regexName.test(params.name)) {
      setAlert({
        ...alert,
        text: '技術・薬品名は半角英数字・半角スペース・ひらがな・カタカナ・漢字で入力してください。',
        is: true,
      });
      return false;
    }

    setAlert({ ...alert, text: '', is: false });
    return true;
  };

  /**
   * 送信ボタン押下時のハンドラ
   */
  const handleSubmitClick = () => {
    // バリデーションチェック
    if (!isValid()) {
      // フォームの最上部までスクロール
      const targetElement = document.getElementById('prescriptionsform');
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
      return;
    }

    // 技術・薬品の登録・更新
    submitPrescriptions(params);

    if (formType === 'create') {
      // paramsの初期化
      setParams({ ...params, optionalId: '', name: '' });
    }
  };

  /**
   * 削除ボタン押下時の処理
   */
  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  /**
   * 削除確認ダイアログで「削除」を押下時の処理
   */
  const handleDeleteCows = () => {
    setIsModalOpen(false);
    deletePrescriptions();
  };

  /**
   * prescriptionが更新されたらStateを更新
   */
  useEffect(() => {
    setParams({
      ...params,
      optionalId: prescription?.optional_id || '',
      name: prescription?.name || '',
    });
  }, [prescription]);

  /**
   * paramsが更新されたらStateを更新
   */
  useEffect(() => {
    // アラート表示をリセット
    setAlert({ ...alert, text: '', is: false });
  }, [params]);

  return (
    <div id="prescriptionsform" className="bg-base-200 mt-12 mb-12">
      <div className="flex flex-col justify-center lg:w-2/3 mx-4 lg:mx-auto">
        <SectionTitle
          svgIcon={
            formType === 'create' ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32l8.4-8.4Z" />
                <path d="M5.25 5.25a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3V13.5a.75.75 0 0 0-1.5 0v5.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V8.25a1.5 1.5 0 0 1 1.5-1.5h5.25a.75.75 0 0 0 0-1.5H5.25Z" />
              </svg>
            )
          }
          title={formType === 'create' ? '技術・薬品登録' : '技術・薬品修正'}
        />
        <div className="max-w-xl">
          <Alert alertText={alert.text} visible={alert.is} />
        </div>
        <div className="form-control max-w-xl">
          <label className="label">
            <span className="label-text">
              ID
              {formType === 'create' && <InputBadge mark="必須" />}
            </span>
          </label>
          <input
            type="text"
            placeholder="001-02"
            className="input input-bordered"
            name="optionalId"
            value={params.optionalId}
            onChange={handleInputChange}
            disabled={formType === 'update'}
          />
        </div>
        <div className="form-control max-w-xl">
          <label className="label">
            <span className="label-text">
              技術・薬品名
              <InputBadge mark="必須" />
            </span>
          </label>
          <input
            type="text"
            placeholder="初診"
            className="input input-bordered"
            name="name"
            value={params.name}
            onChange={handleInputChange}
          />
        </div>
        <button
          className="btn btn-accent w-full max-w-xl mt-6 mb-6"
          onClick={handleSubmitClick}
        >
          送信
        </button>
        {formType === 'update' && (
          <>
            <SectionTitle
              svgIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                    clipRule="evenodd"
                  />
                </svg>
              }
              title="技術・薬品削除"
            />
            <button
              className="btn btn-error w-full max-w-xl mt-6 mb-6"
              onClick={handleDeleteClick}
            >
              削除
            </button>
            <DeleteDialog
              dataName="技術・薬品"
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              detailComponent={
                <div className="text-sm">
                  <div className="py-2">
                    削除すると以下の情報がすべて失われます。
                  </div>
                  <div className="bg-base-200 px-4 py-2">
                    <ul className="list-none">
                      <li className="py-1">- 技術・薬品</li>
                      <li className="py-1">- 技術・薬品に紐づく診察結果詳細</li>
                    </ul>
                  </div>
                </div>
              }
              onDeleteClick={handleDeleteCows}
            />
          </>
        )}
      </div>
    </div>
  );
};
