/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ColumnDef } from '@tanstack/react-table';
import { Cows as CowsData } from '../../API';
import {
  Navbar,
  Footer,
  CowsForm,
  ScrollButton,
  SectionTitle,
  Table,
} from '../../components';

type Props = {
  cows: CowsData[];
  setCows: React.Dispatch<React.SetStateAction<CowsData[]>>;
};

export const Presenter: React.FC<Props> = (props: Props) => {
  const { cows, setCows } = props;

  /**
   * テーブルのカラム設定
   */
  const columns: ColumnDef<{}, any>[] = [
    {
      id: 'select', // idを指定しないとソートができない
      accessorKey: 'optional_id',
      header: '個体識別番号',
      cell: (row) => (
        <a href={`/cows/${row.getValue()}`}>
          <button className="btn btn-link btn-sm text-accent normal-case inline-block gap-0">
            {row.getValue().slice(0, 5)}
            <span className="text-secondary text-lg">
              {row.getValue().slice(5, 9)}
            </span>
            {row.getValue().slice(9, 10)}
          </button>
        </a>
      ),
    },
    {
      accessorKey: 'optional_id',
      header: '診察結果',
      cell: (row) => (
        <a href={`/daily_reports/${row.getValue()}`}>
          <button className="btn btn-secondary btn-sm">表示</button>
        </a>
      ),
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      accessorKey: 'birth_date',
      header: '生年月日',
      enableColumnFilter: false,
    },
    {
      accessorKey: 'sex',
      header: '性別',
      cell: (row: { getValue: () => 'male' | 'female' }) =>
        ({ male: 'オス', female: 'メス' })[row.getValue()],
      enableColumnFilter: false,
    },
    {
      accessorKey: 'birth_weight',
      header: '出生体重',
      cell: (row) => <>{row.getValue() ? `${row.getValue()} kg` : ''} </>,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'neutering_date',
      header: '去勢年月日',
      enableColumnFilter: false,
    },
  ];

  return (
    <div className="flex flex-col h-screen">
      <Navbar />
      <div className="flex flex-col justify-center lg:w-2/3 mx-4 lg:mx-auto">
        <div className="flex justify-between">
          <SectionTitle
            svgIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M2.625 6.75a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0A.75.75 0 018.25 6h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75zM2.625 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zM7.5 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12A.75.75 0 017.5 12zm-4.875 5.25a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75z"
                  clipRule="evenodd"
                />
              </svg>
            }
            title="個体情報一覧"
          />
          <ScrollButton
            targetId="cowsform"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M20.03 4.72a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 11.69l6.97-6.97a.75.75 0 011.06 0zm0 6a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 111.06-1.06L12 17.69l6.97-6.97a.75.75 0 011.06 0z"
                  clipRule="evenodd"
                />
              </svg>
            }
            text="新規登録"
          />
        </div>
        <div className="text-sm mb-4">
          <p className="my-1">
            個体識別番号をタップすると、個体情報詳細が表示されます。
          </p>
        </div>
        <Table data={cows} columns={columns} />
      </div>
      <CowsForm formType="create" cows={cows} setCows={setCows} />
      <Footer />
    </div>
  );
};
