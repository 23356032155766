import { Table } from '@tanstack/react-table';
import { Presenter } from './Presenter';

type Props = {
  table: Table<any>;
};

export const TablePagination: React.FC<Props> = (props: Props) => {
  const { table } = props;

  return <Presenter table={table} />;
};
