/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createPedigree = /* GraphQL */ `mutation CreatePedigree(
  $input: CreatePedigreeInput!
  $condition: ModelPedigreeConditionInput
) {
  createPedigree(input: $input, condition: $condition) {
    id
    optional_id
    sire_optional_id
    dam_optional_id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePedigreeMutationVariables,
  APITypes.CreatePedigreeMutation
>;
export const updatePedigree = /* GraphQL */ `mutation UpdatePedigree(
  $input: UpdatePedigreeInput!
  $condition: ModelPedigreeConditionInput
) {
  updatePedigree(input: $input, condition: $condition) {
    id
    optional_id
    sire_optional_id
    dam_optional_id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePedigreeMutationVariables,
  APITypes.UpdatePedigreeMutation
>;
export const deletePedigree = /* GraphQL */ `mutation DeletePedigree(
  $input: DeletePedigreeInput!
  $condition: ModelPedigreeConditionInput
) {
  deletePedigree(input: $input, condition: $condition) {
    id
    optional_id
    sire_optional_id
    dam_optional_id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePedigreeMutationVariables,
  APITypes.DeletePedigreeMutation
>;
export const createMovementHistory = /* GraphQL */ `mutation CreateMovementHistory(
  $input: CreateMovementHistoryInput!
  $condition: ModelMovementHistoryConditionInput
) {
  createMovementHistory(input: $input, condition: $condition) {
    id
    cowsID
    location
    move_date
    reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMovementHistoryMutationVariables,
  APITypes.CreateMovementHistoryMutation
>;
export const updateMovementHistory = /* GraphQL */ `mutation UpdateMovementHistory(
  $input: UpdateMovementHistoryInput!
  $condition: ModelMovementHistoryConditionInput
) {
  updateMovementHistory(input: $input, condition: $condition) {
    id
    cowsID
    location
    move_date
    reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMovementHistoryMutationVariables,
  APITypes.UpdateMovementHistoryMutation
>;
export const deleteMovementHistory = /* GraphQL */ `mutation DeleteMovementHistory(
  $input: DeleteMovementHistoryInput!
  $condition: ModelMovementHistoryConditionInput
) {
  deleteMovementHistory(input: $input, condition: $condition) {
    id
    cowsID
    location
    move_date
    reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMovementHistoryMutationVariables,
  APITypes.DeleteMovementHistoryMutation
>;
export const createPrescriptions = /* GraphQL */ `mutation CreatePrescriptions(
  $input: CreatePrescriptionsInput!
  $condition: ModelPrescriptionsConditionInput
) {
  createPrescriptions(input: $input, condition: $condition) {
    id
    optional_id
    name
    DailyReportDetails {
      items {
        id
        dailyreportsID
        DailyReports {
          id
          cowsID
          exam_date
          exam_record
          photo1
          photo2
          recorded_by
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        b_point
        a_point
        usage_amount
        unit_price
        prescriptionsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePrescriptionsMutationVariables,
  APITypes.CreatePrescriptionsMutation
>;
export const updatePrescriptions = /* GraphQL */ `mutation UpdatePrescriptions(
  $input: UpdatePrescriptionsInput!
  $condition: ModelPrescriptionsConditionInput
) {
  updatePrescriptions(input: $input, condition: $condition) {
    id
    optional_id
    name
    DailyReportDetails {
      items {
        id
        dailyreportsID
        DailyReports {
          id
          cowsID
          exam_date
          exam_record
          photo1
          photo2
          recorded_by
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        b_point
        a_point
        usage_amount
        unit_price
        prescriptionsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePrescriptionsMutationVariables,
  APITypes.UpdatePrescriptionsMutation
>;
export const deletePrescriptions = /* GraphQL */ `mutation DeletePrescriptions(
  $input: DeletePrescriptionsInput!
  $condition: ModelPrescriptionsConditionInput
) {
  deletePrescriptions(input: $input, condition: $condition) {
    id
    optional_id
    name
    DailyReportDetails {
      items {
        id
        dailyreportsID
        DailyReports {
          id
          cowsID
          exam_date
          exam_record
          photo1
          photo2
          recorded_by
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        b_point
        a_point
        usage_amount
        unit_price
        prescriptionsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePrescriptionsMutationVariables,
  APITypes.DeletePrescriptionsMutation
>;
export const createDailyReportDetails = /* GraphQL */ `mutation CreateDailyReportDetails(
  $input: CreateDailyReportDetailsInput!
  $condition: ModelDailyReportDetailsConditionInput
) {
  createDailyReportDetails(input: $input, condition: $condition) {
    id
    dailyreportsID
    DailyReports {
      id
      cowsID
      Cows {
        id
        DairyReports {
          nextToken
          startedAt
          __typename
        }
        optional_id
        birth_date
        birth_weight
        sex
        neutering_date
        dam_birth_order
        MovementHistories {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DailyReportDetails {
        items {
          id
          dailyreportsID
          b_point
          a_point
          usage_amount
          unit_price
          prescriptionsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      exam_date
      exam_record
      photo1
      photo2
      recorded_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    b_point
    a_point
    usage_amount
    unit_price
    prescriptionsID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDailyReportDetailsMutationVariables,
  APITypes.CreateDailyReportDetailsMutation
>;
export const updateDailyReportDetails = /* GraphQL */ `mutation UpdateDailyReportDetails(
  $input: UpdateDailyReportDetailsInput!
  $condition: ModelDailyReportDetailsConditionInput
) {
  updateDailyReportDetails(input: $input, condition: $condition) {
    id
    dailyreportsID
    DailyReports {
      id
      cowsID
      Cows {
        id
        DairyReports {
          nextToken
          startedAt
          __typename
        }
        optional_id
        birth_date
        birth_weight
        sex
        neutering_date
        dam_birth_order
        MovementHistories {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DailyReportDetails {
        items {
          id
          dailyreportsID
          b_point
          a_point
          usage_amount
          unit_price
          prescriptionsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      exam_date
      exam_record
      photo1
      photo2
      recorded_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    b_point
    a_point
    usage_amount
    unit_price
    prescriptionsID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDailyReportDetailsMutationVariables,
  APITypes.UpdateDailyReportDetailsMutation
>;
export const deleteDailyReportDetails = /* GraphQL */ `mutation DeleteDailyReportDetails(
  $input: DeleteDailyReportDetailsInput!
  $condition: ModelDailyReportDetailsConditionInput
) {
  deleteDailyReportDetails(input: $input, condition: $condition) {
    id
    dailyreportsID
    DailyReports {
      id
      cowsID
      Cows {
        id
        DairyReports {
          nextToken
          startedAt
          __typename
        }
        optional_id
        birth_date
        birth_weight
        sex
        neutering_date
        dam_birth_order
        MovementHistories {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DailyReportDetails {
        items {
          id
          dailyreportsID
          b_point
          a_point
          usage_amount
          unit_price
          prescriptionsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      exam_date
      exam_record
      photo1
      photo2
      recorded_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    b_point
    a_point
    usage_amount
    unit_price
    prescriptionsID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDailyReportDetailsMutationVariables,
  APITypes.DeleteDailyReportDetailsMutation
>;
export const createDailyReports = /* GraphQL */ `mutation CreateDailyReports(
  $input: CreateDailyReportsInput!
  $condition: ModelDailyReportsConditionInput
) {
  createDailyReports(input: $input, condition: $condition) {
    id
    cowsID
    Cows {
      id
      DairyReports {
        items {
          id
          cowsID
          exam_date
          exam_record
          photo1
          photo2
          recorded_by
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      optional_id
      birth_date
      birth_weight
      sex
      neutering_date
      dam_birth_order
      MovementHistories {
        items {
          id
          cowsID
          location
          move_date
          reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    DailyReportDetails {
      items {
        id
        dailyreportsID
        DailyReports {
          id
          cowsID
          exam_date
          exam_record
          photo1
          photo2
          recorded_by
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        b_point
        a_point
        usage_amount
        unit_price
        prescriptionsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    exam_date
    exam_record
    photo1
    photo2
    recorded_by
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDailyReportsMutationVariables,
  APITypes.CreateDailyReportsMutation
>;
export const updateDailyReports = /* GraphQL */ `mutation UpdateDailyReports(
  $input: UpdateDailyReportsInput!
  $condition: ModelDailyReportsConditionInput
) {
  updateDailyReports(input: $input, condition: $condition) {
    id
    cowsID
    Cows {
      id
      DairyReports {
        items {
          id
          cowsID
          exam_date
          exam_record
          photo1
          photo2
          recorded_by
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      optional_id
      birth_date
      birth_weight
      sex
      neutering_date
      dam_birth_order
      MovementHistories {
        items {
          id
          cowsID
          location
          move_date
          reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    DailyReportDetails {
      items {
        id
        dailyreportsID
        DailyReports {
          id
          cowsID
          exam_date
          exam_record
          photo1
          photo2
          recorded_by
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        b_point
        a_point
        usage_amount
        unit_price
        prescriptionsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    exam_date
    exam_record
    photo1
    photo2
    recorded_by
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDailyReportsMutationVariables,
  APITypes.UpdateDailyReportsMutation
>;
export const deleteDailyReports = /* GraphQL */ `mutation DeleteDailyReports(
  $input: DeleteDailyReportsInput!
  $condition: ModelDailyReportsConditionInput
) {
  deleteDailyReports(input: $input, condition: $condition) {
    id
    cowsID
    Cows {
      id
      DairyReports {
        items {
          id
          cowsID
          exam_date
          exam_record
          photo1
          photo2
          recorded_by
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      optional_id
      birth_date
      birth_weight
      sex
      neutering_date
      dam_birth_order
      MovementHistories {
        items {
          id
          cowsID
          location
          move_date
          reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    DailyReportDetails {
      items {
        id
        dailyreportsID
        DailyReports {
          id
          cowsID
          exam_date
          exam_record
          photo1
          photo2
          recorded_by
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        b_point
        a_point
        usage_amount
        unit_price
        prescriptionsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    exam_date
    exam_record
    photo1
    photo2
    recorded_by
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDailyReportsMutationVariables,
  APITypes.DeleteDailyReportsMutation
>;
export const createCows = /* GraphQL */ `mutation CreateCows(
  $input: CreateCowsInput!
  $condition: ModelCowsConditionInput
) {
  createCows(input: $input, condition: $condition) {
    id
    DairyReports {
      items {
        id
        cowsID
        Cows {
          id
          optional_id
          birth_date
          birth_weight
          sex
          neutering_date
          dam_birth_order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DailyReportDetails {
          nextToken
          startedAt
          __typename
        }
        exam_date
        exam_record
        photo1
        photo2
        recorded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    optional_id
    birth_date
    birth_weight
    sex
    neutering_date
    dam_birth_order
    MovementHistories {
      items {
        id
        cowsID
        location
        move_date
        reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCowsMutationVariables,
  APITypes.CreateCowsMutation
>;
export const updateCows = /* GraphQL */ `mutation UpdateCows(
  $input: UpdateCowsInput!
  $condition: ModelCowsConditionInput
) {
  updateCows(input: $input, condition: $condition) {
    id
    DairyReports {
      items {
        id
        cowsID
        Cows {
          id
          optional_id
          birth_date
          birth_weight
          sex
          neutering_date
          dam_birth_order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DailyReportDetails {
          nextToken
          startedAt
          __typename
        }
        exam_date
        exam_record
        photo1
        photo2
        recorded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    optional_id
    birth_date
    birth_weight
    sex
    neutering_date
    dam_birth_order
    MovementHistories {
      items {
        id
        cowsID
        location
        move_date
        reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCowsMutationVariables,
  APITypes.UpdateCowsMutation
>;
export const deleteCows = /* GraphQL */ `mutation DeleteCows(
  $input: DeleteCowsInput!
  $condition: ModelCowsConditionInput
) {
  deleteCows(input: $input, condition: $condition) {
    id
    DairyReports {
      items {
        id
        cowsID
        Cows {
          id
          optional_id
          birth_date
          birth_weight
          sex
          neutering_date
          dam_birth_order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DailyReportDetails {
          nextToken
          startedAt
          __typename
        }
        exam_date
        exam_record
        photo1
        photo2
        recorded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    optional_id
    birth_date
    birth_weight
    sex
    neutering_date
    dam_birth_order
    MovementHistories {
      items {
        id
        cowsID
        location
        move_date
        reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCowsMutationVariables,
  APITypes.DeleteCowsMutation
>;
