/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import {
  DailyReportDetails,
  ModelDailyReportDetailsFilterInput,
  ListDailyReportDetailsQuery,
  ListDailyReportDetailsQueryVariables,
  CreateDailyReportDetailsInput,
  CreateDailyReportDetailsMutation,
  UpdateDailyReportDetailsInput,
  UpdateDailyReportDetailsMutation,
  DeleteDailyReportDetailsMutation,
  DeleteDailyReportDetailsInput
} from '../API';

/**
 * GraphQLでdailyreportsIDをキーにDailyReportDetails一覧を取得
 */
export const fetchDailyReportDetailsByDailyreportsId = async (dailyreportsID: string): Promise<DailyReportDetails[]> => {
  const allItems: DailyReportDetails[] = [];
  let nextToken: string | undefined | null = null;
  const filter: ModelDailyReportDetailsFilterInput = {
    and: [
      { dailyreportsID: { eq: dailyreportsID } },
      { _deleted: { ne: true } }
    ]
  };

  do {
    const variables: ListDailyReportDetailsQueryVariables = {
      filter,
      limit: 20,
      nextToken,
    };

    // eslint-disable-next-line no-await-in-loop
    const response = await API.graphql<GraphQLQuery<ListDailyReportDetailsQuery>>({
      query: queries.listDailyReportDetails,
      variables,
    });

    // @ts-ignore
    const { items, nextToken: newNextToken } = response.data?.listDailyReportDetails || {
      items: [],
      nextToken: null,
    };

    allItems.push(...items);
    nextToken = newNextToken;
  } while (nextToken);

  return allItems;
};

/**
 * GraphQLでDailyReportDetailsに登録
 */
export const createDailyReportDetails = async (
  details: CreateDailyReportDetailsInput,
): Promise<DailyReportDetails> => {
  const item = await API.graphql<GraphQLQuery<CreateDailyReportDetailsMutation>>(
    graphqlOperation(mutations.createDailyReportDetails, { input: details })
  );

  // @ts-ignore
  return item.data.createDailyReportDetails;
};

/**
 * GraphQLでDailyReportDetailsを更新
 */
export const updateDailyReportDetails = async (
  details: UpdateDailyReportDetailsInput
): Promise<void> => {
  await API.graphql<GraphQLQuery<UpdateDailyReportDetailsMutation>>({
    query: mutations.updateDailyReportDetails,
    variables: { input: details },
  });
};

/**
 * GraphQLでDailyReportDetailsからデータを削除
 */
export const deleteDailyReportDetails = async (details: DeleteDailyReportDetailsInput)  => {
  await API.graphql<GraphQLQuery<DeleteDailyReportDetailsMutation>>({ 
    query: mutations.deleteDailyReportDetails, 
    variables: { input: details }
  });
};
