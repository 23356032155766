/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useEffect } from 'react';
import { DailyReports as DailyReportsData } from '../../../API';
import { DailyReportsParams } from '../../../SharedTypes';
import {
  Alert,
  DatepickerInput,
  InputBadge,
  ScrollButton,
  SectionTitle,
  DailyReportDetails,
  PhotoInputForm,
} from '../../../components';

type Props = {
  cowsId: string | undefined;
  dailyReport: DailyReportsData | undefined;
  setExamDate: React.Dispatch<React.SetStateAction<string | undefined>>;
  submitDailyReports: (params: any) => void;
};

export const Presenter: React.FC<Props> = (props: Props) => {
  const { cowsId, dailyReport, setExamDate, submitDailyReports } = props;

  const [params, setParams] = useState<DailyReportsParams>({
    examDate: '',
    recordedBy: '',
    examRecord: '',
    photo1: '',
    photo2: '',
  });
  const [alert, setAlert] = useState({ text: '', is: false });

  /**
   * 現在時刻をYYYY-MM-DD形式に整形
   */
  const getCurrentDateInJST = (): string => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  /**
   * 日付変更時のハンドラ
   * @param pickedDate
   */
  const handleDateChange = (pickedDate: Date) => {
    // 選択した日付を整形する
    const formattedDate = `${pickedDate.getFullYear()}-${(
      pickedDate.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}-${pickedDate.getDate().toString().padStart(2, '0')}`;

    // 選択した日付をparams.examDateに設定
    setParams({ ...params, examDate: formattedDate });

    // 選択した日付をsetExamDateで設定
    setExamDate(formattedDate);
  };

  /**
   * インプット変更時のハンドラ
   * @param event
   */
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setParams({ ...params, [event.target.name]: event.target.value });
  };

  /**
   * テキストエリア変更時のハンドラ
   * @param event
   */
  const handleTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setParams({ ...params, [event.target.name]: event.target.value });
  };

  /**
   * バリデーションチェック
   * @returns
   */
  const isValid = (): boolean => {
    // 診察日
    if (params.examDate.length === 0) {
      setAlert({
        ...alert,
        text: '診察日を入力してください。',
        is: true,
      });
      return false;
    }
    const regexDate = /^\d{4}-\d{2}-\d{2}$/;
    if (!regexDate.test(params.examDate)) {
      setAlert({
        ...alert,
        text: '診察日が無効な日付形式です。',
        is: true,
      });
      return false;
    }

    // 記入者
    if (!params.recordedBy || params.recordedBy.length === 0) {
      setAlert({
        ...alert,
        text: '記入者を入力してください。',
        is: true,
      });
      return false;
    }
    // eslint-disable-next-line no-irregular-whitespace
    const regexRecordedBy = /^[a-zA-Z0-9ぁ-んァ-ヶー一-龠　]*$/;
    if (!regexRecordedBy.test(params.recordedBy)) {
      setAlert({
        ...alert,
        text: '記入者は半角英数字・ひらがな・カタカナ・漢字・全角スペースで入力してください。',
        is: true,
      });
      return false;
    }

    // 診察記録
    if (params.examRecord) {
      const regexRecord = /^[a-zA-Z0-9ぁ-んァ-ヶー一-龠、。！？＆（）/()\n]*$/;
      if (!regexRecord.test(params.examRecord)) {
        setAlert({
          ...alert,
          text: '診察記録は半角英数字・ひらがな・カタカナ・漢字・一部の記号（句読点や！？等）・改行で入力してください。',
          is: true,
        });
        return false;
      }
    }

    setAlert({ ...alert, text: '', is: false });
    return true;
  };

  /**
   * 送信ボタン押下時のハンドラ
   */
  const handleSubmitClick = () => {
    // バリデーションチェック
    if (!isValid()) {
      // フォームの最上部までスクロール
      const targetElement = document.getElementById('dailyreportform');
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
      return;
    }

    // 診察結果の登録・更新
    submitDailyReports(params);
  };

  /**
   * dailyReportが更新されたらStateを更新
   */
  useEffect(() => {
    // 当該日付のdailyReportが存在しない場合
    if (!dailyReport) {
      setParams({
        ...params,
        examDate: params.examDate || getCurrentDateInJST(),
        recordedBy: '',
        examRecord: '',
        photo1: '',
        photo2: '',
      });
      return;
    }

    // 当該日付のdailyReportが存在する場合
    setParams({
      ...params,
      examDate: dailyReport.exam_date,
      recordedBy: dailyReport.recorded_by || '',
      examRecord: dailyReport.exam_record || '',
      photo1: dailyReport.photo1 || '',
      photo2: dailyReport.photo2 || '',
    });
  }, [dailyReport]);

  /**
   * paramsが更新されたらStateを更新
   */
  useEffect(() => {
    // アラート表示をリセット
    setAlert({ ...alert, text: '', is: false });
  }, [params]);

  return (
    <div id="dailyreportform" className="bg-base-200 mt-12 mb-2">
      <div className="flex flex-col justify-center lg:w-2/3 mx-4 lg:mx-auto">
        <div className="flex justify-between gap-x-2">
          <SectionTitle
            svgIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625ZM7.5 15a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 7.5 15Zm.75 2.25a.75.75 0 0 0 0 1.5H12a.75.75 0 0 0 0-1.5H8.25Z"
                  clipRule="evenodd"
                />
                <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
              </svg>
            }
            title="診察結果"
          />
          <ScrollButton
            targetId="dailyreports"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M20.03 4.72a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 11.69l6.97-6.97a.75.75 0 011.06 0zm0 6a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 111.06-1.06L12 17.69l6.97-6.97a.75.75 0 011.06 0z"
                  clipRule="evenodd"
                />
              </svg>
            }
            text="診察結果一覧"
          />
        </div>
        <div className="max-w-xl">
          <Alert alertText={alert.text} visible={alert.is} />
        </div>
        <div className="form-control max-w-xl">
          <label className="label">
            <span className="label-text">
              診察日
              <InputBadge mark="必須" />
            </span>
          </label>
          <DatepickerInput
            name="examDate"
            value={params.examDate}
            onChange={handleDateChange}
          />
        </div>
        <div className="form-control max-w-xl">
          <label className="label">
            <span className="label-text">
              記入者
              <InputBadge mark="必須" />
            </span>
          </label>
          <input
            className="input input-bordered"
            name="recordedBy"
            placeholder="山田"
            value={params.recordedBy}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-control max-w-xl">
          <label className="label">
            <span className="label-text">
              診察記録
              <InputBadge mark="任意" />
            </span>
          </label>
          <textarea
            className="textarea textarea-bordered"
            rows={6}
            name="examRecord"
            placeholder="検査の結果、口内潰瘍が確認されました。治療として口腔用抗生物質の処方を勧めます。再診は1週間後。"
            value={params.examRecord}
            onChange={handleTextAreaChange}
          ></textarea>
        </div>
        <PhotoInputForm
          cowsId={cowsId}
          label="写真1"
          paramName="photo1"
          params={params}
          setParams={setParams}
        />
        <PhotoInputForm
          cowsId={cowsId}
          label="写真2"
          paramName="photo2"
          params={params}
          setParams={setParams}
        />
        <button
          className="btn btn-accent w-full max-w-xl mt-6 mb-6"
          onClick={handleSubmitClick}
        >
          送信
        </button>
        <div className="divider"></div>
        <DailyReportDetails dailyReportsId={dailyReport?.id} />
      </div>
    </div>
  );
};
