/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useNavigate } from 'react-router-dom';
import * as apis from '../../../apis';
import {
  Cows as CowsData,
  CreateCowsInput,
  UpdateCowsInput,
  CreateMovementHistoryInput,
  DeleteCowsInput,
} from '../../../API';
import { CowsParams, MovementHistoryParams } from '../../../SharedTypes';
import { useToast } from '../../../contexts';
import { Presenter } from './Presenter';

type Props = {
  formType: 'create' | 'update';
  cows?: CowsData[];
  setCows?: React.Dispatch<React.SetStateAction<CowsData[]>>;
  cow?: CowsData;
};

export const CowsForm: React.FC<Props> = (props: Props) => {
  const { formType, cows, setCows, cow } = props;

  const showToast = useToast();
  const navigate = useNavigate();

  /**
   * optionalIdが重複していないかチェックする
   * @param optionalId
   * @returns
   */
  const checkDuplicateId = (optionalId: string) => {
    if (!cows) {
      return false;
    }

    const isDuplicate = cows.some((item) => item.optional_id === optionalId);
    return isDuplicate;
  };

  /**
   * 送信ボタン押下時のAPI実行処理
   * @param cowsParams
   * @param movementHistoryParams
   */
  const submitCows = async (
    cowsParams: CowsParams,
    movementHistoryParams: MovementHistoryParams,
  ) => {
    try {
      if (formType === 'create') {
        // 個体識別番号の重複チェック
        if (checkDuplicateId(cowsParams.optionalId)) {
          showToast({
            text: `個体識別番号が重複しているため登録できませんでした。`,
            type: 'warning',
          });
          return;
        }

        // 個体情報データ登録用パラメータ
        const cowsDetails: CreateCowsInput = {
          optional_id: cowsParams.optionalId,
          birth_date: cowsParams.birthDate,
          birth_weight: cowsParams.birthWeight,
          sex: cowsParams.sex,
          neutering_date: cowsParams.neuteringDate,
          dam_birth_order: cowsParams.damBirthOrder,
        };

        // 個体情報データ登録
        const createResult = await apis.createCows(cowsDetails);

        // 移動履歴データ登録用パラメータ
        const movementHistoryDetails: CreateMovementHistoryInput = {
          cowsID: createResult.id,
          location: movementHistoryParams.location,
          move_date: cowsParams.birthDate,
          reason: '出生',
        };

        // 移動履歴データ登録
        await apis.createMovementHistory(movementHistoryDetails);

        showToast({
          text: `データを登録しました。`,
          type: 'success',
        });

        // 個体情報一覧を再取得
        if (!setCows) {
          return;
        }
        const fetchResult = await apis.fetchCows();
        setCows(fetchResult);
      } else if (formType === 'update') {
        if (!cow) {
          return;
        }

        // データ更新用パラメータ
        const details: UpdateCowsInput = {
          id: cow.id,
          optional_id: cowsParams.optionalId,
          birth_date: cowsParams.birthDate,
          birth_weight: cowsParams.birthWeight,
          sex: cowsParams.sex,
          neutering_date: cowsParams.neuteringDate,
          dam_birth_order: cowsParams.damBirthOrder,
          _version: cow._version,
        };

        // データ更新
        await apis.updateCows(details);

        showToast({
          text: `データを更新しました。`,
          type: 'success',
        });
      }
    } catch (error) {
      showToast({
        text: `エラーが発生しました。${JSON.stringify(error)}`,
        type: 'error',
      });
    }
  };

  /**
   * 削除ボタン押下時のAPI実行処理
   */
  const deleteCows = () => {
    if (!cow) {
      return;
    }

    // データ削除用パラメータ
    const details: DeleteCowsInput = {
      id: cow.id,
      _version: cow._version,
    };

    // データ削除
    apis
      .deleteCows(details)
      .then(() => {
        showToast({
          text: `データを削除しました。`,
          type: 'success',
        });

        // 個体情報一覧へ遷移
        navigate('/cows');
      })
      .catch((error) => {
        showToast({
          text: `データ削除に失敗しました。${JSON.stringify(error)}`,
          type: 'error',
        });
      });
  };

  return (
    <Presenter
      formType={formType}
      cow={cow}
      submitCows={submitCows}
      deleteCows={deleteCows}
    />
  );
};
