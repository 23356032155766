type Props = {
  cowsOptionalId: string;
};
export const Presenter: React.FC<Props> = (props: Props) => {
  const { cowsOptionalId } = props;

  // publicフォルダ
  const image = process.env.PUBLIC_URL;

  return (
    <div className="relative w-60">
      <img src={`${image}/eartag.png`} width="100%" />
      <p className="absolute top-36 left-20 text-zinc-500 wf-mplus1code tracking-widest text-3xl">
        {cowsOptionalId?.slice(0, 5)}
      </p>
      <p className="absolute top-56 left-7 text-zinc-500 wf-mplus1code tracking-wider text-7xl">
        {cowsOptionalId?.slice(5, 9)}
      </p>
      <p className="absolute top-60 left-48 text-zinc-500 wf-mplus1code tracking-wider text-5xl">
        {cowsOptionalId?.slice(9, 10)}
      </p>
    </div>
  );
};
