import { Cows as CowsData } from '../../../API';
import { PedigreeParams } from '../../../SharedTypes';
import { Presenter } from './Presenter';

type Props = {
  name: string;
  params: {
    [key: string]: PedigreeParams;
  };
  cows: CowsData[];
  getParentPedigreeData: (name: string, value: string) => void;
  setChildPedigreeData: (name: string, value: string) => void;
};

export const PedigreeInput: React.FC<Props> = (props: Props) => {
  const { name, params, cows, getParentPedigreeData, setChildPedigreeData } =
    props;

  return (
    <Presenter
      name={name}
      params={params}
      cows={cows}
      getParentPedigreeData={getParentPedigreeData}
      setChildPedigreeData={setChildPedigreeData}
    />
  );
};
