import { useEffect, useState } from 'react';
import * as apis from '../../../apis';
import { MovementHistory as MovementHistoryData } from '../../../API';
import { useToast } from '../../../contexts';
import { Presenter } from './Presenter';

type Props = {
  cowsId: string | undefined;
};

export const MovementHistory: React.FC<Props> = (props: Props) => {
  const { cowsId } = props;

  const [movementHistories, setMovementHistories] = useState<
    MovementHistoryData[]
  >([]);

  const showToast = useToast();

  /**
   * データの取得
   */
  const fetchData = () => {
    apis
      .fetchMovementHistoriesByCowsId(cowsId)
      .then((result) => {
        setMovementHistories(result);
      })
      .catch((error) => {
        showToast({
          text: `データ取得に失敗しました。${JSON.stringify(error)}`,
          type: 'error',
        });
      });
  };

  /**
   * 最初に移動履歴一覧を取得
   */
  useEffect(() => {
    fetchData();
  }, [cowsId]);

  return (
    <Presenter
      cowsId={cowsId}
      movementHistories={movementHistories}
      setMovementHistories={setMovementHistories}
    />
  );
};
