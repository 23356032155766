type Props = {
  svgIcon: JSX.Element;
  title: string;
};

export const Presenter: React.FC<Props> = (props: Props) => {
  const { title, svgIcon } = props;

  return (
    <div className="flex justify-start items-center mt-8 mb-2">
      {svgIcon}
      <span className="font-bold mx-2">{title}</span>
    </div>
  );
};
