/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import {
  Pedigree,
  ModelPedigreeFilterInput,
  ListPedigreesQuery,
  ListPedigreesQueryVariables,
  CreatePedigreeInput,
  CreatePedigreeMutation,
  UpdatePedigreeInput,
  UpdatePedigreeMutation,
  DeletePedigreeInput,
  DeletePedigreeMutation
} from '../API';

/**
 * GraphQLでPedigree一覧を取得
 */
export const fetchPedigrees = async (
): Promise<Pedigree[]> => {
  const allItems: Pedigree[] = [];
  let nextToken: string | undefined | null = null;
  const filter: ModelPedigreeFilterInput = { _deleted: { ne: true } };

  do {
    const variables: ListPedigreesQueryVariables = {
      filter,
      limit: 20,
      nextToken,
    };

    // eslint-disable-next-line no-await-in-loop
    const response = await API.graphql<GraphQLQuery<ListPedigreesQuery>>({
      query: queries.listPedigrees,
      variables,
    });

    // @ts-ignore
    const { items, nextToken: newNextToken } = response.data?.listPedigrees || {
      items: [],
      nextToken: null,
    };

    allItems.push(...items);
    nextToken = newNextToken;
  } while (nextToken);

  return allItems;
};

/**
 * GraphQLでOptionalIdをキーにPedigree一覧を取得
 */
export const fetchPedigreesByOptionalId = async (
  optionalId: string | null | undefined,
): Promise<Pedigree[]> => {
  if (!optionalId) {
    return [];
  }

  const allItems: Pedigree[] = [];
  let nextToken: string | undefined | null = null;
  const filter: ModelPedigreeFilterInput = {
    and: [
      { optional_id: { eq: optionalId } },
      { _deleted: { ne: true } }
    ]
  };

  do {
    const variables: ListPedigreesQueryVariables = {
      filter,
      limit: 20,
      nextToken,
    };

    // eslint-disable-next-line no-await-in-loop
    const response = await API.graphql<GraphQLQuery<ListPedigreesQuery>>({
      query: queries.listPedigrees,
      variables,
    });

    // @ts-ignore
    const { items, nextToken: newNextToken } = response.data?.listPedigrees || {
      items: [],
      nextToken: null,
    };

    allItems.push(...items);
    nextToken = newNextToken;
  } while (nextToken);

  return allItems;
};

/**
 * GraphQLでPedigreeに登録
 */
export const createPedigree = async (
  details: CreatePedigreeInput,
): Promise<Pedigree> => {
  const item = await API.graphql<GraphQLQuery<CreatePedigreeMutation>>(
    graphqlOperation(mutations.createPedigree, { input: details })
  );

  // @ts-ignore
  return item.data.createPedigree;
};

/**
 * GraphQLでPedigreeを更新
 */
export const updatePedigree = async (
  details: UpdatePedigreeInput
): Promise<void> => {
  await API.graphql<GraphQLQuery<UpdatePedigreeMutation>>({
    query: mutations.updatePedigree,
    variables: { input: details },
  });
};

/**
 * GraphQLでPedigreeからデータを削除
 */
export const deletePedigree = async (details: DeletePedigreeInput)  => {
  await API.graphql<GraphQLQuery<DeletePedigreeMutation>>({ 
    query: mutations.deletePedigree, 
    variables: { input: details }
  });
};
