import { useEffect, useState } from 'react';
import * as apis from '../../../apis';
import {
  DailyReportDetails as DailyReportDetailsData,
  Prescriptions as PrescriptionsData,
} from '../../../API';
import { useToast } from '../../../contexts';
import { Presenter } from './Presenter';

type Props = {
  dailyReportsId: string | undefined;
};

export const DailyReportDetails: React.FC<Props> = (props: Props) => {
  const { dailyReportsId } = props;

  const [prescriptions, setPrescriptions] = useState<PrescriptionsData[]>([]);
  const [dailyReportDetails, setDailyReportDetails] = useState<
    DailyReportDetailsData[]
  >([]);

  const showToast = useToast();

  /**
   * データの取得
   */
  const fetchData = () => {
    // dailyReportsIdがない場合は空配列をセット
    if (!dailyReportsId) {
      setDailyReportDetails([]);
      return;
    }

    // dailyReportsIdがある場合はデータを取得
    apis
      .fetchDailyReportDetailsByDailyreportsId(dailyReportsId)
      .then((result) => {
        // dailyReport.prescriptionsIDがprescriptionsに含まれない場合、表示しない
        const filteredResult = result.filter((dailyReport) =>
          prescriptions.some(
            (prescription) => prescription.id === dailyReport.prescriptionsID,
          ),
        );
        setDailyReportDetails(filteredResult);
      })
      .catch((error) => {
        showToast({
          text: `データ取得に失敗しました。${JSON.stringify(error)}`,
          type: 'error',
        });
      });
  };

  /**
   * 最初に技術・薬品一覧を取得
   */
  useEffect(() => {
    apis
      .fetchPrescriptions()
      .then((result) => {
        setPrescriptions(result);
      })
      .catch((error) => {
        showToast({
          text: `データ取得に失敗しました。${JSON.stringify(error)}`,
          type: 'error',
        });
      });
  }, []);

  /**
   * 最初に診察結果詳細一覧を取得
   */
  useEffect(() => {
    fetchData();
  }, [dailyReportsId]);

  return (
    <Presenter
      dailyReportsId={dailyReportsId}
      dailyReportDetails={dailyReportDetails}
      setDailyReportDetails={setDailyReportDetails}
      prescriptions={prescriptions}
    />
  );
};
