import { Footer, Navbar } from '../../components';

export const Presenter: React.FC = () => (
  <div className="flex flex-col h-screen">
    <Navbar />
    <div className="flex flex-col items-center m-24">
      <p className="font-bold leading-tight text-2xl lg:text-5xl mt-0 mb-2">
        404
      </p>
      <p className="font-medium leading-tight text-xl lg:text-4xl mt-0 mb-2">
        Page Not Found
      </p>
      <div className="flex flex-col w-1/2 border-opacity-50">
        <div className="divider"></div>
      </div>
      <p>申し訳ございません。</p>
      <p>お探しのページは見つかりませんでした。</p>
    </div>
    <Footer />
  </div>
);
