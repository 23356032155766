import { Presenter } from './Presenter';

type Props = {
  mark: string;
};

export const InputBadge: React.FC<Props> = (props: Props) => {
  const { mark } = props;

  return <Presenter mark={mark} />;
};
