import { useState, useEffect } from 'react';
import { MovementHistory as MovementHistoryData } from '../../../API';
import { MovementHistoryParams } from '../../../SharedTypes';
import { Alert, InputBadge, DatepickerInput } from '../../../components';

type Props = {
  cowsId: string | undefined;
  editMovementHistory: MovementHistoryData | undefined;
  submitMovementHistory: (params: MovementHistoryParams) => void;
};

export const Presenter: React.FC<Props> = (props: Props) => {
  const { cowsId, editMovementHistory, submitMovementHistory } = props;

  const [params, setParams] = useState<MovementHistoryParams>({
    location: '',
  });
  const [alert, setAlert] = useState({ text: '', is: false });

  /**
   * Input変更時のハンドラ
   * @param e
   */
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setParams({ ...params, [e.target.name]: e.target.value });
  };

  /**
   * 日付変更時のハンドラ
   * @param pickedDate
   */
  const handleDateChange = (pickedDate: Date) => {
    // 選択した日付を整形する
    const formattedDate = `${pickedDate.getFullYear()}-${(
      pickedDate.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}-${pickedDate.getDate().toString().padStart(2, '0')}`;

    // 選択した日付をparams.moveDateに設定
    setParams({ ...params, moveDate: formattedDate });
  };

  /**
   * バリデーションチェック
   * @returns
   */
  const isValid = (): boolean => {
    // params.locationのチェック
    if (params.location.length === 0) {
      setAlert({
        ...alert,
        text: '牧場を入力してください。',
        is: true,
      });
      return false;
    }
    if (params.location) {
      const regex = /^[a-zA-Z0-9ぁ-んァ-ヶー一-龠]*$/;
      if (!regex.test(params.location)) {
        setAlert({
          ...alert,
          text: '牧場は半角英数字・ひらがな・カタカナ・漢字・ハイフンで入力してください。',
          is: true,
        });
        return false;
      }
    }

    // params.moveDateのチェック
    if (!params.moveDate || params.moveDate.length === 0) {
      setAlert({
        ...alert,
        text: '移動日を入力してください。',
        is: true,
      });
      return false;
    }
    if (params.moveDate) {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (!regex.test(params.moveDate)) {
        setAlert({
          ...alert,
          text: '移動日が無効な日付形式です。',
          is: true,
        });
        return false;
      }
    }

    // params.reasonのチェック
    if (!params.reason || params.reason.length === 0) {
      setAlert({
        ...alert,
        text: '移動理由を入力してください。',
        is: true,
      });
      return false;
    }
    if (params.reason) {
      const regex = /^[a-zA-Z0-9ぁ-んァ-ヶー一-龠]*$/;
      if (!regex.test(params.reason)) {
        setAlert({
          ...alert,
          text: '移動理由は半角英数字・ひらがな・カタカナ・漢字・ハイフンで入力してください。',
          is: true,
        });
        return false;
      }
    }

    setAlert({ ...alert, text: '', is: false });
    return true;
  };

  /**
   * 送信ボタン押下時のハンドラ
   */
  const handleSubmitClick = () => {
    // バリデーションチェック
    if (!isValid()) {
      return;
    }

    // 移動履歴の登録・更新
    submitMovementHistory(params);

    // paramsの初期化
    setParams({ location: '' });
  };

  /**
   * cowsIdが更新されたらStateを更新
   */
  useEffect(() => {
    // paramsの初期化
    setParams({ location: '' });
  }, [cowsId]);

  /**
   * editMovementHistoryが更新されたらStateを更新
   */
  useEffect(() => {
    // editMovementHistoryが存在しない場合
    if (!editMovementHistory) {
      // paramsの初期化
      setParams({ location: '' });
      return;
    }

    // editMovementHistoryが存在する場合
    setParams({
      location: editMovementHistory?.location,
      moveDate: editMovementHistory?.move_date,
      reason: editMovementHistory?.reason,
    });
  }, [editMovementHistory]);

  /**
   * paramsが更新されたらStateを更新
   */
  useEffect(() => {
    // アラート表示をリセット
    setAlert({ ...alert, text: '', is: false });
  }, [params]);

  return (
    <>
      <Alert alertText={alert.text} visible={alert.is} />
      <div className="lg:flex lg:flex-wrap items-end mb-6 lg:gap-x-4">
        <div className="form-control max-w-xl">
          <label className="label">
            <span className="label-text">
              牧場
              <InputBadge mark="必須" />
            </span>
          </label>
          <input
            type="text"
            placeholder="ABC牧場"
            className={`input input-bordered input-sm ${
              editMovementHistory ? 'input-secondary' : ''
            }`}
            name="location"
            value={params.location ?? ''}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-control max-w-xl">
          <label className="label">
            <span className="label-text">
              移動日
              <InputBadge mark="必須" />
            </span>
          </label>
          <DatepickerInput
            name="moveDate"
            value={params.moveDate ?? ''}
            onChange={handleDateChange}
            inputSize="input-sm"
            inputColor={editMovementHistory ? 'input-secondary' : ''}
          />
        </div>
        <div className="form-control max-w-xl">
          <label className="label">
            <span className="label-text">
              移動理由
              <InputBadge mark="必須" />
            </span>
          </label>
          <input
            type="text"
            placeholder="出生"
            className={`input input-bordered input-sm ${
              editMovementHistory ? 'input-secondary' : ''
            }`}
            name="reason"
            value={params.reason ?? ''}
            onChange={handleInputChange}
          />
        </div>
        <button
          className="btn btn-accent btn-sm w-full max-w-xl lg:w-fit mt-6"
          onClick={handleSubmitClick}
        >
          送信
        </button>
      </div>
    </>
  );
};
