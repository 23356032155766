/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as apis from '../../apis';
import { DailyReports as DailyReportsData } from '../../API';
import { useToast } from '../../contexts';
import { Presenter } from './Presenter';

export const DailyReports = () => {
  const [cowsId, setCowsId] = useState<string>();
  const [dailyReports, setDailyReports] = useState<DailyReportsData[]>([]);

  const showToast = useToast();
  const urlParams = useParams<{ cowsOptionalId: string }>();
  const { cowsOptionalId } = urlParams;
  const navigate = useNavigate();

  /**
   * 最初にcowsIdを取得
   */
  useEffect(() => {
    apis
      .fetchCowsByOptionalId(cowsOptionalId)
      .then((result) => {
        if (result.length === 0) {
          // データがない場合はNotFoundへ遷移
          navigate('/notfound');
          return;
        }
        setCowsId(result[0].id);
      })
      .catch((error) => {
        showToast({
          text: `データ取得に失敗しました。${JSON.stringify(error)}`,
          type: 'error',
        });
      });
  }, []);

  /**
   * cowsIdが取得できたら診察結果一覧を取得
   */
  useEffect(() => {
    if (!cowsId) {
      return;
    }

    // cowsIdに紐づく診察結果一覧
    apis
      .fetchDailyReportsByCowsId(cowsId)
      .then((result) => {
        setDailyReports(result);
      })
      .catch((error) => {
        showToast({
          text: `データ取得に失敗しました。${JSON.stringify(error)}`,
          type: 'error',
        });
      });
  }, [cowsId]);

  return (
    <Presenter
      cowsId={cowsId}
      cowsOptionalId={cowsOptionalId}
      dailyReports={dailyReports}
      setDailyReports={setDailyReports}
    />
  );
};
