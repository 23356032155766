type Props = {
  targetId: string;
  icon?: JSX.Element;
  text?: string;
};

export const Presenter: React.FC<Props> = (props: Props) => {
  const { targetId, icon, text } = props;

  /**
   * クリックした際にtargetIdのElementまでスクロール
   */
  const handleClick = () => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    // contentがstringだったらw-fit、Element（svgを想定）だったらbtn-circleを追加
    <div
      className={`btn btn-accent btn-outline btn-sm ${
        text ? 'w-fit' : 'btn-circle'
      } mt-8 mb-2`}
      onClick={handleClick}
    >
      {icon}
      {text}
    </div>
  );
};
