import { useEffect, useState } from 'react';
import { ScrollRestoration } from 'react-router-dom';
import * as apis from '../../apis';
import { Cows as CowsData } from '../../API';
import { useToast } from '../../contexts';
import { Presenter } from './Presenter';

export const Cows = () => {
  const [cows, setCows] = useState<CowsData[]>([]);

  const showToast = useToast();

  /**
   * 最初に個体情報一覧を取得
   */
  useEffect(() => {
    apis
      .fetchCows()
      .then((result) => {
        setCows(result);
      })
      .catch((error) => {
        showToast({
          text: `データ取得に失敗しました。${JSON.stringify(error)}`,
          type: 'error',
        });
      });
  }, []);

  return (
    <>
      <ScrollRestoration />
      <Presenter cows={cows} setCows={setCows} />
    </>
  );
};
