/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useEffect } from 'react';
import { Cows as CowsData, Pedigree as PedigreeData } from '../../../API';
import { PedigreeParams } from '../../../SharedTypes';
import { SectionTitle, PedigreeInput } from '../../../components';

type Props = {
  cow: CowsData | undefined;
  cows: CowsData[];
  pedigrees: PedigreeData[];
  getPedigree: (optionalId: string) => Promise<PedigreeData | undefined>;
  submitPedigree: (params: PedigreeParams) => void;
};

type CombinedArray = (CowsData | PedigreeData)[];

export const Presenter: React.FC<Props> = (props: Props) => {
  const { cow, cows, pedigrees, getPedigree, submitPedigree } = props;

  const [params, setParams] = useState<{
    [key: string]: PedigreeParams;
  }>({});

  // 管理する世代数
  const generation = 5;

  /**
   * 自身と親の血統情報を取得
   * @param name
   * @param optionalId
   */
  const getParentPedigreeData = (name: string, optionalId: string) => {
    // 親の世代
    const parentGeneration = String.fromCharCode(name.charCodeAt(0) + 1);

    // 親のname属性
    const sireName = `${parentGeneration + name.slice(1)}-sire`;
    const damName = `${parentGeneration + name.slice(1)}-dam`;

    // データベースから自身と親の血統情報を取得
    getPedigree(optionalId)
      .then((result) => {
        if (result) {
          // データが存在する場合、データベースから取得した値をparamsの自身の血統情報として設定
          const newParam: PedigreeParams = {
            optionalId: result.optional_id,
            sireOptionalId: result.sire_optional_id,
            damOptionalId: result.dam_optional_id,
          };

          setParams((prevParams) => ({
            ...prevParams,
            [name]: newParam,
          }));

          // 親の世代が規定値未満の場合、親の血統情報を取得
          if (Number(parentGeneration) < generation) {
            getParentPedigreeData(sireName, result.sire_optional_id || '');
            getParentPedigreeData(damName, result.dam_optional_id || '');
          }
        } else {
          // データが存在しない場合、paramsから親の血統情報を削除し、optional_idをparamsの自身の血統情報として設定
          setParams((prevParams) => {
            const {
              // 親の血統情報
              [sireName]: deletedKey1,
              [damName]: deletedKey2,
              ...restParams
            } = prevParams;
            return {
              ...restParams,
              // 自身の血統情報
              [name]: { optionalId },
            };
          });

          // 親の世代が規定値未満の場合、さらに親の血統情報を削除
          if (Number(parentGeneration) < generation) {
            getParentPedigreeData(sireName, '');
            getParentPedigreeData(damName, '');
          }
        }
      })
      .catch(() => {});
  };

  /**
   * 自身と子の血統情報を設定
   * @param name
   * @param optionalId
   */
  const setChildPedigreeData = (name: string, optionalId: string) => {
    // データベースから自身の血統情報を取得
    getParentPedigreeData(name, optionalId);

    // 子の世代
    const childGeneration = String.fromCharCode(name.charCodeAt(0) - 1);

    // 自身が子のどちらの親か
    const parent = name.substring(name.lastIndexOf('-') + 1);

    // 子のname属性
    const childName = `${
      childGeneration + name.slice(1).replace(new RegExp(`-${parent}$`), '')
    }`;

    // 子の血統情報
    const newParams = {
      ...params[childName],
      [`${parent}OptionalId`]: optionalId,
    };

    // 子の血統情報をparamsに設定
    setParams((prevParams) => ({
      ...prevParams,
      [childName]: newParams,
    }));

    // 子の血統情報の登録・更新
    submitPedigree(newParams);
  };

  /**
   * 個体情報と血統情報からユニークな配列を作成
   * @param cowsArray
   * @param pedigreeArray
   * @returns
   */
  const combineArrays = (
    cowsArray: CowsData[],
    pedigreeArray: PedigreeData[],
  ): CombinedArray => {
    const combinedArray: CombinedArray = [...cowsArray, ...pedigreeArray];

    // optional_idをキーとしてユニークなエントリを取得する
    const uniqueEntries = combinedArray.reduce(
      (acc: Record<string, CowsData | PedigreeData>, item) => {
        const key = item.optional_id;

        // Cowsのデータを優先して取得する
        if (
          !acc[key] ||
          (item.__typename === 'Cows' && acc[key].__typename !== 'Cows')
        ) {
          acc[key] = item;
        }

        return acc;
      },
      {},
    );

    // ユニークなエントリを配列に変換して返す
    return Object.values(uniqueEntries);
  };

  /**
   * 個体情報と血統情報から作成するDatalistコンポーネント
   * @returns
   */
  const Datalist = () => {
    const resultArray: CombinedArray = combineArrays(cows, pedigrees);

    const options = resultArray.map((item) => {
      if (item.__typename === 'Cows') {
        return (
          <option key={item.optional_id} value={item.optional_id}>
            {`生年月日: ${item.birth_date || '-'} | 性別: ${
              item.sex === 'male'
                ? 'オス'
                : item.sex === 'female'
                ? 'メス'
                : '-'
            }`}
          </option>
        );
      }
      if (item.__typename === 'Pedigree') {
        return (
          <option key={item.optional_id} value={item.optional_id}>
            {`父: ${item.sire_optional_id || '-'} | 母: ${
              item.dam_optional_id || '-'
            }`}
          </option>
        );
      }
      return null;
    });

    return <datalist id="optionalIdList">{options}</datalist>;
  };

  /**
   * cowが更新されたら表示中の個体情報の血統情報を取得
   */
  useEffect(() => {
    if (!cow) {
      return;
    }
    getParentPedigreeData('0', cow.optional_id);
  }, [cow]);

  return (
    <div className="flex flex-col justify-center lg:w-2/3 mx-4 lg:mx-auto">
      <div className="flex items-end mb-2 gap-x-2">
        <SectionTitle
          svgIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path d="M5.625 3.75a2.625 2.625 0 1 0 0 5.25h12.75a2.625 2.625 0 0 0 0-5.25H5.625ZM3.75 11.25a.75.75 0 0 0 0 1.5h16.5a.75.75 0 0 0 0-1.5H3.75ZM3 15.75a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75ZM3.75 18.75a.75.75 0 0 0 0 1.5h16.5a.75.75 0 0 0 0-1.5H3.75Z" />
            </svg>
          }
          title="5代血統"
        />
      </div>
      <div className="text-sm mb-4">
        <p className="my-1">
          鉛筆ボタン
          <button className="btn btn-xs btn-square mr-1" disabled={true}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="w-4 h-4"
            >
              <path
                fillRule="evenodd"
                d="M11.013 2.513a1.75 1.75 0 0 1 2.475 2.474L6.226 12.25a2.751 2.751 0 0 1-.892.596l-2.047.848a.75.75 0 0 1-.98-.98l.848-2.047a2.75 2.75 0 0 1 .596-.892l7.262-7.261Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          をタップして入力、飛行機ボタン
          <button className="btn btn-xs btn-square mr-1" disabled={true}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="w-4 h-4"
            >
              <path d="M2.87 2.298a.75.75 0 0 0-.812 1.021L3.39 6.624a1 1 0 0 0 .928.626H8.25a.75.75 0 0 1 0 1.5H4.318a1 1 0 0 0-.927.626l-1.333 3.305a.75.75 0 0 0 .811 1.022 24.89 24.89 0 0 0 11.668-5.115.75.75 0 0 0 0-1.175A24.89 24.89 0 0 0 2.869 2.298Z" />
            </svg>
          </button>
          をタップして送信します。
        </p>
        <p className="my-1">
          牧場に登録されている個体情報および血統情報からの選択、あるいは自由入力が可能です。
        </p>
        <p className="my-1">
          両親の血統情報が登録済みの場合は自動的に反映されます。
        </p>
      </div>
      <div className="overflow-auto">
        <Datalist />
        <table className="table table-sm border border-slate-500">
          <thead>
            <tr>
              <th className="border border-slate-500 w-56">父母</th>
              <th className="border border-slate-500 w-56">祖父母</th>
              <th className="border border-slate-500 w-56">曽祖父母</th>
              <th className="border border-slate-500 w-56">高祖父母</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="bg-blue-50 border border-slate-500" rowSpan={8}>
                <PedigreeInput
                  name="1-sire"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
              <td className="bg-blue-50 border border-slate-500" rowSpan={4}>
                <PedigreeInput
                  name="2-sire-sire"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
              <td className=" bg-blue-50 border border-slate-500" rowSpan={2}>
                <PedigreeInput
                  name="3-sire-sire-sire"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
              <td className="bg-blue-50 border border-slate-500">
                <PedigreeInput
                  name="4-sire-sire-sire-sire"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-pink-50 border border-slate-500">
                <PedigreeInput
                  name="4-sire-sire-sire-dam"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-pink-50 border border-slate-500" rowSpan={2}>
                <PedigreeInput
                  name="3-sire-sire-dam"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
              <td className="bg-blue-50 border border-slate-500">
                <PedigreeInput
                  name="4-sire-sire-dam-sire"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-pink-50 border border-slate-500">
                <PedigreeInput
                  name="4-sire-sire-dam-dam"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-pink-50 border border-slate-500" rowSpan={4}>
                <PedigreeInput
                  name="2-sire-dam"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
              <td className="bg-blue-50 border border-slate-500" rowSpan={2}>
                <PedigreeInput
                  name="3-sire-dam-sire"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
              <td className="bg-blue-50 border border-slate-500">
                <PedigreeInput
                  name="4-sire-dam-sire-sire"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-pink-50 border border-slate-500">
                <PedigreeInput
                  name="4-sire-dam-sire-dam"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-pink-50 border border-slate-500" rowSpan={2}>
                <PedigreeInput
                  name="3-sire-dam-dam"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
              <td className="bg-blue-50 border border-slate-500">
                <PedigreeInput
                  name="4-sire-dam-dam-sire"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-pink-50 border border-slate-500">
                <PedigreeInput
                  name="4-sire-dam-dam-dam"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-pink-50 border border-slate-500" rowSpan={8}>
                <PedigreeInput
                  name="1-dam"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
              <td className="bg-blue-50 border border-slate-500" rowSpan={4}>
                <PedigreeInput
                  name="2-dam-sire"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
              <td className="bg-blue-50 border border-slate-500" rowSpan={2}>
                <PedigreeInput
                  name="3-dam-sire-sire"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
              <td className="bg-blue-50 border border-slate-500">
                <PedigreeInput
                  name="4-dam-sire-sire-sire"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-pink-50 border border-slate-500">
                <PedigreeInput
                  name="4-dam-sire-sire-dam"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-pink-50 border border-slate-500" rowSpan={2}>
                <PedigreeInput
                  name="3-dam-sire-dam"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
              <td className="bg-blue-50 border border-slate-500">
                <PedigreeInput
                  name="4-dam-sire-dam-sire"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-pink-50 border border-slate-500">
                <PedigreeInput
                  name="4-dam-sire-dam-dam"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-pink-50 border border-slate-500" rowSpan={4}>
                <PedigreeInput
                  name="2-dam-dam"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
              <td className="bg-blue-50 border border-slate-500" rowSpan={2}>
                <PedigreeInput
                  name="3-dam-dam-sire"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
              <td className="bg-blue-50 border border-slate-500">
                <PedigreeInput
                  name="4-dam-dam-sire-sire"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-pink-50 border border-slate-500">
                <PedigreeInput
                  name="4-dam-dam-sire-dam"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-pink-50 border border-slate-500" rowSpan={2}>
                <PedigreeInput
                  name="3-dam-dam-dam"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
              <td className="bg-blue-50 border border-slate-500">
                <PedigreeInput
                  name="4-dam-dam-dam-sire"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-pink-50 border border-slate-500">
                <PedigreeInput
                  name="4-dam-dam-dam-dam"
                  params={params}
                  cows={cows}
                  getParentPedigreeData={getParentPedigreeData}
                  setChildPedigreeData={setChildPedigreeData}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
