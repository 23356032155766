import { Presenter } from './Presenter';

type Props = {
  dataName: string;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  detailComponent?: React.ReactNode;
  onDeleteClick: () => void;
};

export const DeleteDialog: React.FC<Props> = (props: Props) => {
  const {
    dataName,
    isModalOpen,
    setIsModalOpen,
    detailComponent,
    onDeleteClick,
  } = props;

  return (
    <Presenter
      dataName={dataName}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      detailComponent={detailComponent}
      onDeleteClick={onDeleteClick}
    />
  );
};
