import React, { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import { useToast } from '../../../contexts';
import { DailyReportsParams } from '../../../SharedTypes';
import { Presenter } from './Presenter';

type Props = {
  cowsId: string | undefined;
  label: string;
  paramName: string;
  params: DailyReportsParams;
  setParams: React.Dispatch<React.SetStateAction<DailyReportsParams>>;
};

export const PhotoInputForm: React.FC<Props> = (props: Props) => {
  const { cowsId, label, paramName, params, setParams } = props;

  const [photoURL, setPhotoURL] = useState<string>();

  const showToast = useToast();

  /**
   * 写真ファイルのURLを取得
   * @param fileName
   * @returns
   */
  const getPhotoURL = async (fileName: string) => {
    if (!cowsId) {
      return;
    }

    // cowsIdのディレクトリ配下から取得
    const key = `${cowsId}/${fileName}`;

    try {
      const signedURL = await Storage.get(key);
      setPhotoURL(signedURL);
    } catch (error) {
      showToast({
        text: `ファイルの取得に失敗しました。${JSON.stringify(error)}`,
        type: 'error',
      });
    }
  };

  /**
   * 写真ファイルをアップロード
   * @param fileName
   * @param file
   * @returns
   */
  const uploadPhoto = async (fileName: string, file: File | undefined) => {
    if (!file || !cowsId) {
      showToast({
        text: `ファイルがアップロードできません。`,
        type: 'warning',
      });
      return;
    }

    // cowsIdのディレクトリ配下に格納する
    const uploadFileName = `${cowsId}/${fileName}`;

    try {
      await Storage.put(uploadFileName, file, {});
    } catch (error) {
      showToast({
        text: `ファイルのアップロードに失敗しました。${JSON.stringify(error)}`,
        type: 'error',
      });
    }
  };

  /**
   * paramsのphotoに値がある場合に写真ファイルのURLを取得
   */
  useEffect(() => {
    const paramsPhoto = params[paramName as keyof typeof params];

    if (paramsPhoto) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getPhotoURL(paramsPhoto);
    } else {
      setPhotoURL('');
    }
  }, [params]);

  return (
    <Presenter
      label={label}
      paramName={paramName}
      params={params}
      setParams={setParams}
      photoURL={photoURL}
      getPhotoURL={getPhotoURL}
      uploadPhoto={uploadPhoto}
    />
  );
};
