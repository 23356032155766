/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import {
  DailyReports,
  ModelDailyReportsFilterInput,
  ListDailyReportsQuery,
  ListDailyReportsQueryVariables,
  CreateDailyReportsInput,
  CreateDailyReportsMutation,
  UpdateDailyReportsInput,
  UpdateDailyReportsMutation,
  DeleteDailyReportsInput,
  DeleteDailyReportsMutation
} from '../API';

/**
 * GraphQLでDailyReports一覧を取得
 */
export const fetchDailyReports = async (): Promise<DailyReports[]> => {
  const allItems: DailyReports[] = [];
  let nextToken: string | undefined | null = null;
  const filter: ModelDailyReportsFilterInput = { _deleted: { ne: true } };

  do {
    const variables: ListDailyReportsQueryVariables = {
      filter,
      limit: 20,
      nextToken,
    };

    // eslint-disable-next-line no-await-in-loop
    const response = await API.graphql<GraphQLQuery<ListDailyReportsQuery>>({
      query: queries.listDailyReports,
      variables,
    });

    // @ts-ignore
    const { items, nextToken: newNextToken } = response.data?.listDailyReports || {
      items: [],
      nextToken: null,
    };

    allItems.push(...items);
    nextToken = newNextToken;
  } while (nextToken);

  return allItems;
};

/**
 * GraphQLでcowsIDをキーにDailyReports一覧を取得
 */
export const fetchDailyReportsByCowsId = async (cowsID: string): Promise<DailyReports[]> => {
  const allItems: DailyReports[] = [];
  let nextToken: string | undefined | null = null;
  const filter: ModelDailyReportsFilterInput = {
    and: [
      { cowsID: { eq: cowsID } },
      { _deleted: { ne: true } }
    ]
  };

  do {
    const variables: ListDailyReportsQueryVariables = {
      filter,
      limit: 20,
      nextToken,
    };

    // eslint-disable-next-line no-await-in-loop
    const response = await API.graphql<GraphQLQuery<ListDailyReportsQuery>>({
      query: queries.listDailyReports,
      variables,
    });

    // @ts-ignore
    const { items, nextToken: newNextToken } = response.data?.listDailyReports || {
      items: [],
      nextToken: null,
    };

    allItems.push(...items);
    nextToken = newNextToken;
  } while (nextToken);

  // exam_dateの降順にソート
  allItems.sort((a, b) => new Date(b.exam_date).getTime() - new Date(a.exam_date).getTime());

  return allItems;
};

/**
 * GraphQLでcowsIDとexam_dateをキーにDailyReports一覧を取得
 */
export const fetchDailyReportsByExamDate = async (cowsId: string, date: string): Promise<DailyReports[]> => {
  const allItems: DailyReports[] = [];
  let nextToken: string | undefined | null = null;
  const filter: ModelDailyReportsFilterInput = {
    and: [
      { cowsID: { eq: cowsId } },
      { exam_date: { eq: date } },
      { _deleted: { ne: true } }
    ]
  };

  do {
    const variables: ListDailyReportsQueryVariables = {
      filter,
      limit: 20,
      nextToken,
    };

    // eslint-disable-next-line no-await-in-loop
    const response = await API.graphql<GraphQLQuery<ListDailyReportsQuery>>({
      query: queries.listDailyReports,
      variables,
    });

    // @ts-ignore
    const { items, nextToken: newNextToken } = response.data?.listDailyReports || {
      items: [],
      nextToken: null,
    };

    allItems.push(...items);
    nextToken = newNextToken;
  } while (nextToken);

  return allItems;
};

/**
 * GraphQLでDailyReportsに登録
 */
export const createDailyReports = async (
  details: CreateDailyReportsInput,
): Promise<DailyReports> => {
  const item = await API.graphql<GraphQLQuery<CreateDailyReportsMutation>>(
    graphqlOperation(mutations.createDailyReports, { input: details })
  );

  // @ts-ignore
  return item.data.createDailyReports;
};

/**
 * GraphQLでDailyReportsを更新
 */
export const updateDailyReports = async (
  details: UpdateDailyReportsInput
): Promise<void> => {
  await API.graphql<GraphQLQuery<UpdateDailyReportsMutation>>({
    query: mutations.updateDailyReports,
    variables: { input: details },
  });
};

/**
 * GraphQLでDailyReportsからデータを削除
 */
export const deleteDailyReports = async (details: DeleteDailyReportsInput)  => {
  await API.graphql<GraphQLQuery<DeleteDailyReportsMutation>>({ 
    query: mutations.deleteDailyReports, 
    variables: { input: details }
  });
};
