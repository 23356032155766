type Props = {
  dataName: string;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  detailComponent?: React.ReactNode;
  onDeleteClick: () => void;
};
export const Presenter: React.FC<Props> = (props: Props) => {
  const {
    dataName,
    isModalOpen,
    setIsModalOpen,
    detailComponent,
    onDeleteClick,
  } = props;

  return (
    <dialog
      id="delete-modal"
      className={`modal ${isModalOpen ? 'modal-open' : ''}`}
    >
      <div className="modal-box">
        <div className="avatar placeholder">
          <div className="bg-error text-error-content rounded-full w-12">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-8 h-8"
            >
              <path
                fillRule="evenodd"
                d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
        <p className="text-lg py-4">{dataName}を本当に削除しますか？</p>
        {detailComponent}
        <div className="modal-action">
          <form method="dialog">
            <div className="flex gap-2">
              <button
                className="btn btn-sm"
                onClick={() => setIsModalOpen(false)}
              >
                キャンセル
              </button>
              <button className="btn btn-sm btn-error" onClick={onDeleteClick}>
                削除
              </button>
            </div>
          </form>
        </div>
      </div>
    </dialog>
  );
};
