import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as apis from '../../apis';
import { Cows as CowsData } from '../../API';
import { useToast } from '../../contexts';
import { Presenter } from './Presenter';

export const CowsDetails = () => {
  const [cow, setCow] = useState<CowsData>();

  const showToast = useToast();
  const urlParams = useParams<{ cowsOptionalId: string }>();
  const { cowsOptionalId } = urlParams;
  const navigate = useNavigate();

  /**
   * 最初に個体情報を取得
   */
  useEffect(() => {
    apis
      .fetchCowsByOptionalId(cowsOptionalId)
      .then((result) => {
        if (result.length === 0) {
          // データがない場合はNotFoundへ遷移
          navigate('/notfound');
          return;
        }
        setCow(result[0]);
      })
      .catch((error) => {
        showToast({
          text: `データ取得に失敗しました。${JSON.stringify(error)}`,
          type: 'error',
        });
      });
  }, []);

  return <Presenter cow={cow} />;
};
