import { useState, useEffect } from 'react';
import { Cows as CowsData } from '../../../API';
import {
  Alert,
  DatepickerInput,
  DeleteDialog,
  InputBadge,
  SectionTitle,
  MovementHistory,
} from '../../../components';
import { CowsParams, MovementHistoryParams } from '../../../SharedTypes';

type Props = {
  formType: 'create' | 'update';
  cow?: CowsData;
  submitCows: (
    cowsParams: CowsParams,
    movementHistoryParams: MovementHistoryParams,
  ) => void;
  deleteCows: () => void;
};

export const Presenter: React.FC<Props> = (props: Props) => {
  const { formType, cow, submitCows, deleteCows } = props;

  const [cowsParams, setCowsParams] = useState<CowsParams>({
    optionalId: '',
    birthDate: '',
    birthWeight: null,
    sex: '',
    neuteringDate: '',
    damBirthOrder: null,
  });
  const [movementHistoryParams, setMovementHistoryParams] =
    useState<MovementHistoryParams>({
      location: '',
    });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alert, setAlert] = useState({ text: '', is: false });

  /**
   * 個体情報インプット変更時のハンドラ
   * @param event
   */
  const handleCowsParamsInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setCowsParams({ ...cowsParams, [event.target.name]: event.target.value });
  };

  /**
   * 移動履歴インプット変更時のハンドラ
   * @param event
   */
  const handleMovementHistoryParamsInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setMovementHistoryParams({
      ...movementHistoryParams,
      [event.target.name]: event.target.value,
    });
  };

  /**
   * 選択した日付を整形する
   * @param pickedDate
   * @returns formattedDate
   */
  const formattedDate = (pickedDate: Date) =>
    `${pickedDate.getFullYear()}-${(pickedDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${pickedDate.getDate().toString().padStart(2, '0')}`;

  /**
   * 生年月日変更時のハンドラ
   * @param pickedDate
   */
  const handleBirthDateChange = (pickedDate: Date) => {
    // 選択した日付をcowsParams.birthDateに設定
    setCowsParams({ ...cowsParams, birthDate: formattedDate(pickedDate) });
  };

  /**
   * 去勢年月日の変更時のハンドラ
   */
  const handleNeuteringDateChange = (pickedDate: Date) => {
    // 選択した日付をcowsParams.neuteringDateに設定
    setCowsParams({ ...cowsParams, neuteringDate: formattedDate(pickedDate) });
  };

  /**
   * バリデーションチェック
   * @returns
   */
  const isValid = (): boolean => {
    // 個体識別番号
    if (cowsParams.optionalId.length === 0) {
      setAlert({
        ...alert,
        text: '個体識別番号を入力してください。',
        is: true,
      });
      return false;
    }
    const regexOptionalId = /^\d{10}$/;
    if (!regexOptionalId.test(cowsParams.optionalId)) {
      setAlert({
        ...alert,
        text: '個体識別番号には10桁の数字を入力してください。',
        is: true,
      });
      return false;
    }

    // 生年月日
    if (cowsParams.birthDate.length === 0) {
      setAlert({
        ...alert,
        text: '生年月日を入力してください。',
        is: true,
      });
      return false;
    }
    const regexBirthDate = /^\d{4}-\d{2}-\d{2}$/;
    if (!regexBirthDate.test(cowsParams.birthDate)) {
      setAlert({
        ...alert,
        text: '生年月日が無効な日付形式です。',
        is: true,
      });
      return false;
    }

    // 出生体重
    if (!cowsParams.birthWeight) {
      setAlert({
        ...alert,
        text: '出生体重を入力してください。',
        is: true,
      });
      return false;
    }
    const regexBirthWeight = /^\d{1,3}$/;
    if (!regexBirthWeight.test(cowsParams.birthWeight.toString())) {
      setAlert({
        ...alert,
        text: '出生体重は半角数字で入力してください。',
        is: true,
      });
      return false;
    }

    // 母親の何番目に生まれたか
    if (!cowsParams.damBirthOrder) {
      setAlert({
        ...alert,
        text: '母親の何番目に生まれたかを入力してください。',
        is: true,
      });
      return false;
    }
    const regexDamBirthOrder = /^\d{1,2}$/;
    if (!regexDamBirthOrder.test(cowsParams.damBirthOrder.toString())) {
      setAlert({
        ...alert,
        text: '母親の何番目に生まれたかは半角数字で入力してください。',
        is: true,
      });
      return false;
    }

    // 性別
    const regexSex = /^(male|female)$/;
    if (!regexSex.test(cowsParams.sex)) {
      setAlert({
        ...alert,
        text: '性別を選択してください。',
        is: true,
      });
      return false;
    }

    // 去勢年月日
    const regexNeuteringDate = /^$|^\d{4}-\d{2}-\d{2}$/;
    if (!regexNeuteringDate.test(cowsParams.neuteringDate)) {
      setAlert({
        ...alert,
        text: '去勢年月日が無効な日付形式です。',
        is: true,
      });
      return false;
    }

    // 生まれた牧場
    if (formType === 'create') {
      if (movementHistoryParams.location.length === 0) {
        setAlert({
          ...alert,
          text: '生まれた牧場を入力してください。',
          is: true,
        });
        return false;
      }
      if (movementHistoryParams.location) {
        const regex = /^[a-zA-Z0-9ぁ-んァ-ヶー一-龠]*$/;
        if (!regex.test(movementHistoryParams.location)) {
          setAlert({
            ...alert,
            text: '生まれた牧場は半角英数字・ひらがな・カタカナ・漢字・ハイフンで入力してください。',
            is: true,
          });
          return false;
        }
      }
    }

    setAlert({ ...alert, text: '', is: false });
    return true;
  };

  /**
   * 送信ボタン押下時のハンドラ
   */
  const handleSubmitClick = () => {
    // バリデーションチェック
    if (!isValid()) {
      // フォームの最上部までスクロール
      const targetElement = document.getElementById('cowsform');
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
      return;
    }

    // 個体情報の登録・更新
    submitCows(cowsParams, movementHistoryParams);

    if (formType === 'create') {
      // cowsParamsの初期化
      setCowsParams({
        ...cowsParams,
        optionalId: '',
        birthDate: '',
        birthWeight: undefined,
        sex: '',
        neuteringDate: '',
        damBirthOrder: undefined,
      });
      // movementHistoryParamsの初期化
      setMovementHistoryParams({ ...movementHistoryParams, location: '' });
    }
  };

  /**
   * 削除ボタン押下時の処理
   */
  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  /**
   * 削除確認ダイアログで「削除」を押下時の処理
   */
  const handleDeleteCows = () => {
    setIsModalOpen(false);
    deleteCows();
  };

  /**
   * cowが更新されたらStateを更新
   */
  useEffect(() => {
    setCowsParams({
      ...cowsParams,
      optionalId: cow?.optional_id || '',
      birthDate: cow?.birth_date || '',
      birthWeight: cow?.birth_weight || undefined,
      sex: cow?.sex || '',
      neuteringDate: cow?.neutering_date || '',
      damBirthOrder: cow?.dam_birth_order || undefined,
    });
  }, [cow]);

  /**
   * paramsが更新されたらStateを更新
   */
  useEffect(() => {
    // アラート表示をリセット
    setAlert({ ...alert, text: '', is: false });
  }, [cowsParams, movementHistoryParams]);

  return (
    <div id="cowsform" className="bg-base-200 mt-12 mb-12">
      <div className="flex flex-col justify-center lg:w-2/3 mx-4 lg:mx-auto">
        <SectionTitle
          svgIcon={
            formType === 'create' ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32l8.4-8.4Z" />
                <path d="M5.25 5.25a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3V13.5a.75.75 0 0 0-1.5 0v5.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V8.25a1.5 1.5 0 0 1 1.5-1.5h5.25a.75.75 0 0 0 0-1.5H5.25Z" />
              </svg>
            )
          }
          title={formType === 'create' ? '個体情報登録' : '個体情報修正'}
        />
        <div className="max-w-xl">
          <Alert alertText={alert.text} visible={alert.is} />
        </div>
        {formType === 'create' && (
          <div className="form-control max-w-xl">
            <label className="label">
              <span className="label-text">
                個体識別番号
                <InputBadge mark="必須" />
              </span>
            </label>
            <input
              type="text"
              placeholder="0000000000"
              className="input input-bordered"
              name="optionalId"
              value={cowsParams.optionalId}
              onChange={handleCowsParamsInputChange}
            />
          </div>
        )}
        <div className="form-control max-w-xl">
          <label className="label">
            <span className="label-text">
              生年月日
              <InputBadge mark="必須" />
            </span>
          </label>
          <DatepickerInput
            name="birthDate"
            value={cowsParams.birthDate}
            onChange={handleBirthDateChange}
          />
        </div>
        <div className="lg:flex lg:flex-wrap items-end lg:gap-x-4">
          <div className="form-control max-w-xl">
            <label className="label">
              <span className="label-text">
                出生体重
                <InputBadge mark="必須" />
              </span>
            </label>
            <span>
              <input
                type="text"
                placeholder="40"
                className="input input-bordered"
                name="birthWeight"
                value={cowsParams.birthWeight || ''}
                onChange={handleCowsParamsInputChange}
              />
              <span className="text-xs mx-2">kg</span>
            </span>
          </div>
          <div className="form-control max-w-xl">
            <label className="label">
              <span className="label-text">
                母親の何番目に生まれたか
                <InputBadge mark="必須" />
              </span>
            </label>
            <span>
              <input
                type="text"
                inputMode="numeric"
                placeholder="1"
                className="input input-bordered"
                name="damBirthOrder"
                value={cowsParams.damBirthOrder || ''}
                onChange={handleCowsParamsInputChange}
              />
              <span className="text-xs mx-2">番目</span>
            </span>
          </div>
        </div>
        <div className="form-control max-w-xl">
          <label className="label">
            <span className="label-text">
              性別
              <InputBadge mark="必須" />
            </span>
          </label>
          <select
            className="select select-bordered"
            name="sex"
            value={cowsParams.sex}
            onChange={handleCowsParamsInputChange}
          >
            <option value="">選択してください</option>
            <option value="male">オス</option>
            <option value="female">メス</option>
          </select>
        </div>
        <div className="form-control max-w-xl">
          <label className="label">
            <span className="label-text">
              去勢年月日
              <InputBadge mark="任意" />
            </span>
          </label>
          <DatepickerInput
            name="neuteringDate"
            value={cowsParams.neuteringDate}
            onChange={handleNeuteringDateChange}
          />
        </div>
        {formType === 'create' && (
          <div className="form-control max-w-xl">
            <label className="label">
              <span className="label-text">
                生まれた牧場
                <InputBadge mark="必須" />
              </span>
            </label>
            <input
              type="text"
              placeholder="ABC牧場"
              className="input input-bordered"
              name="location"
              value={movementHistoryParams.location}
              onChange={handleMovementHistoryParamsInputChange}
            />
          </div>
        )}
        <button
          className="btn btn-accent w-full max-w-xl mt-6 mb-6"
          onClick={handleSubmitClick}
        >
          送信
        </button>
        {formType === 'update' && (
          <>
            <SectionTitle
              svgIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                    clipRule="evenodd"
                  />
                </svg>
              }
              title="個体情報削除"
            />
            <button
              className="btn btn-error w-full max-w-xl mt-6 mb-6"
              onClick={handleDeleteClick}
            >
              削除
            </button>
            <DeleteDialog
              dataName="個体情報"
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              detailComponent={
                <div className="text-sm">
                  <div className="py-2">
                    削除すると以下の情報がすべて失われます。
                  </div>
                  <div className="bg-base-200 px-4 py-2">
                    <ul className="list-none">
                      <li className="py-1">- 個体情報</li>
                      <li className="py-1">- 個体情報に紐づく移動履歴</li>
                      <li className="py-1">- 個体情報に紐づく診察結果</li>
                      <li className="py-1">
                        - 個体情報に紐づく診察結果詳細（技術・薬品）
                      </li>
                    </ul>
                  </div>
                </div>
              }
              onDeleteClick={handleDeleteCows}
            />
          </>
        )}
        {formType === 'update' && (
          <>
            <div className="divider"></div>
            <MovementHistory cowsId={cow?.id} />
          </>
        )}
      </div>
    </div>
  );
};
