import { useEffect, useState } from 'react';
import * as apis from '../../apis';
import { Prescriptions as PrescriptionsData } from '../../API';
import { useToast } from '../../contexts';
import { Presenter } from './Presenter';

export const Prescriptions = () => {
  const [prescriptions, setPrescriptions] = useState<PrescriptionsData[]>([]);

  const showToast = useToast();

  /**
   * 最初に技術・薬品一覧を取得
   */
  useEffect(() => {
    apis
      .fetchPrescriptions()
      .then((result) => {
        setPrescriptions(result);
      })
      .catch((error) => {
        showToast({
          text: `データ取得に失敗しました。${JSON.stringify(error)}`,
          type: 'error',
        });
      });
  }, []);

  return (
    <Presenter
      prescriptions={prescriptions}
      setPrescriptions={setPrescriptions}
    />
  );
};
